import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { VERIFICATION_REDIRECT_URL } from '../../../config';
import { axios } from '../../../lib/axios';
import { UserRegistrationInput } from '../types';

const register = async (payload: UserRegistrationInput) => {
  const response = await axios.post('/user/register', {
    ...payload,
    redirect_url: VERIFICATION_REDIRECT_URL,
  });
  return { ...response.data, email: payload.email };
};

export const resendEmail = async (email: string) => {
  const response = await axios.post('/user/resend-email/', {
    email,
    redirect_url: VERIFICATION_REDIRECT_URL,
  });

  return response.data;
};

const verifyEmail = async (token: string) => {
  const response = await axios.get(`/user/verify/${token}`);

  return response.data;
};
/*ib
const sendForm = async (data: FormValues) => {
  // Make a POST request to your backend API endpoint
  const response = await axios.post('/user/enquiry/', data);

  // Return the response data
  return response.data;
};

ib*/
export const useRegister = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: register,
    onSuccess: (data) => {
      if (
        data.status === 'success' &&
        (data.code === 200 || data.code === 201)
      ) {
        navigate('/auth/registration-success', {
          state: { email: data.email },
        });
      }
    },
  });
};

export const useResendEmail = () => {
  return useMutation({
    mutationFn: resendEmail,
  });
};

export const useVerifyEmail = () => {
  return useMutation({
    mutationFn: verifyEmail,
  });
};
/*ib
export const useSendForm = () => {
  return useMutation({
    mutationFn: sendForm,
  });
};
ib*/
