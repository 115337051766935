import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { object, string } from 'zod';

import { Button, Link, PasswordInput } from '../../../components/Elements';
import { Head } from '../../../components/Head';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import { notifyError, notifySuccess } from '../../../utils/notifications';
import styles from '../../../utils/styles';
import { useResetPassword } from '../api/resetPassword';
import Header from '../components/Header';

const resetPasswordSchema = object({
  token: string().min(1, 'Token is required'),
  password: string().min(1, 'Password is required'),
  confirmPassword: string().min(1, 'confirm password is required'),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ['confirmPassword'],
});

export type ResetPasswordInput = {
  token: string;
  password: string;
  confirmPassword: string;
};

export const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const token = search.split('?token=')[1];

  const {
    mutate: resetPassword,
    isPending,
    isSuccess,
    isError,
    data,
    error,
    reset,
  } = useResetPassword();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: resetForm,
  } = useForm<ResetPasswordInput>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      token,
    },
  });
  if (isSuccess) {
    notifySuccess(data.data?.message, false);
    reset();
    resetForm();
    setTimeout(() => {
      navigate('/auth/login', {
        state: 'reset-password',
      });
    }, 2000);
  }

  if (isError) {
    notifyError(getErrorMsg(error as unknown as ErrorProps), false);
    reset();
  }

  const onSubmit: SubmitHandler<ResetPasswordInput> = (
    data: ResetPasswordInput,
  ) => {
    resetPassword(data);
  };

  return (
    <>
      <Head title='Set New Password' />
      <div className='flex flex-col px-8 max-w-lg mx-auto py-8 sm:py-16'>
        <Header title='Set New Password' subTitle='Create a New Password' />
        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          {errors && errors.token && (
            <div className='text-sm font-semibold text-red-500 mb-2 text-center'>{`${errors.token.message}`}</div>
          )}
          <div className={styles.formControl}>
            <input {...register('token')} type='hidden' />
            <label htmlFor='password' className={styles.label}>
              Password
            </label>
            <PasswordInput
              placeholder='enter your password'
              registration={register('password')}
            />
            <p className={styles.error}>{errors.password?.message as string}</p>
          </div>
          <div className={styles.formControl}>
            <label htmlFor='confirmPassword' className={styles.label}>
              Confirm Password
            </label>
            <PasswordInput
              placeholder='re-enter your password'
              registration={register('confirmPassword')}
            />
            <p className={styles.error}>
              {errors.confirmPassword?.message as string}
            </p>
          </div>
          <div className='mt-8 flex justify-start'>
            <Button type='submit' variant='primary' isLoading={isPending}>
              Save Password
            </Button>
          </div>

          <div className='text-center mt-4 text-sm'>
            <Link
              to='/auth/login'
              className='text-secondary font-semibold hover:text-primary'
            >
              Return to Sign in
            </Link>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};
