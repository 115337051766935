import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

export const getStudentCourses = async () => {
  const resp = await axios.get('/sessions/course-progress');
  return resp.data;
};

export const useGetStudentCourses = () => {
  return useQuery({
    queryKey: ['student-courses'],
    queryFn: getStudentCourses,
    select: ({ data }) => {
      const { completed_details, progress_details } = data;

      return {
        ...data,
        progress_details: completed_details.length
          ? [...completed_details, ...progress_details]
          : progress_details,
      };
    },
    staleTime: 5 * 60 * 1000,
  });
};
