import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

const getLiveSession = async (id: string) => {
  const resp = await axios.get(`/sessions/live/${id}`);
  const liveSession = resp.data;

  if (liveSession && liveSession.data?.id) {
    const course = await getCourseDetails(liveSession.data.course_id);
    if (course) {
      liveSession.data['sections'] = course.sections;
    }
  }

  return liveSession;
};

const getCategories = async () => {
  const resp = await axios.get('/sessions/category');
  return resp.data;
};

export const getCourseDetails = async (courseID: string) => {
  const resp = await axios.get(`/sessions/courses/${courseID}/`);
  return resp.data;
};

const getOngoingLiveSessions = async () => {
  const response = await axios.get('/sessions/live/?status=ongoing');

  return response.data;
};

export const getSectionDetails = async (sectionID: string) => {
  const resp = await axios.get(`/sessions/sections/${sectionID}/`);
  return resp.data;
};

export const useGetOngoingLiveSessions = () => {
  return useQuery({
    queryFn: getOngoingLiveSessions,
    queryKey: ['ongoing-live-sessions'],
    staleTime: 24 * 60 * 60 * 1000,
    select: (data) => {
      return data?.data?.results;
    },
  });
};

export const useGetLiveSession = (id: string) => {
  return useQuery({
    queryKey: [`live-session_${id}`],
    queryFn: () => getLiveSession(id),
    select: (data) => {
      return { ...data?.data, image_id: data?.data.image };
    },
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetCategories = () => {
  return useQuery({
    queryFn: getCategories,
    queryKey: ['categories'],
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const useGetSectionDetails = (id: string) => {
  return useQuery({
    queryKey: [`live-session-section_${id}`],
    queryFn: () => getSectionDetails(id),
  });
};
