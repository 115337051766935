import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

const getStudents = async () => {
  const response = await axios.get('/adminapp/students/');
  return response.data;
};
export const useGetStudents = () => {
  return useQuery({
    queryKey: ['students'],
    queryFn: getStudents,
    select: (data) => data?.data?.students,
    staleTime: 24 * 60 * 60 * 1000,
  });
};
