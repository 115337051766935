import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Head } from '../../../../components/Head';
import { removeEmptyProperties } from '../../../../utils/converter';
import { useScheduleLiveSession } from '../api/scheduleLiveSession';
import { LiveSessionForm } from '../components/LiveSessionForm';
import { ScheduleLiveSessionInput } from '../types';

export const ScheduleLiveSession = () => {
  const navigate = useNavigate();
  const {
    mutate: scheduleLiveSession,
    isError,
    isSuccess,
    isPending,
    error,
    reset,
  } = useScheduleLiveSession();

  const defaultValues = {
    class_days: ['saturday', 'sunday'],
    questions: [
      { question_text: '' /*type: 'text', options: []*/ },
      { question_text: '' /*type: 'text', options: []*/ },
    ],
  };

  const handleSubmit = (data: ScheduleLiveSessionInput) => {
    const cleanedPayload = removeEmptyProperties(data);
    scheduleLiveSession(cleanedPayload);
  };

  const handleCancel = () => {
    navigate('/admin/live-sessions');
  };

  return (
    <>
      <Head title='Schedule Live Session' />
      <LiveSessionForm
        defaultValues={defaultValues}
        mode='create'
        onSubmit={handleSubmit}
        isSubmitting={isPending}
        onCancel={handleCancel}
        isSuccess={isSuccess}
        isError={isError}
        successMsg={'Live Session Scheduled Successfully.'}
        errorMsg={
          //'An error has occured, please try again.'
          error ? (error as any).response?.data : null
        }
        reset={reset}
      />
      <ToastContainer />
    </>
  );
};
