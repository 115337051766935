import { useMutation } from '@tanstack/react-query';

import { PASSWORD_RESET_REDIRECT_URL } from '../../../config';
import { axios } from '../../../lib/axios';
import { RequestPasswordResetInput } from '../routes/RequestPasswordReset';

const requestPasswordReset = async (payload: RequestPasswordResetInput) => {
  const response = await axios.post('/user/request-password-reset/', {
    ...payload,
    redirect_url: PASSWORD_RESET_REDIRECT_URL,
  });
  return response.data;
};

export const useRequestPasswordReset = () => {
  return useMutation({
    mutationFn: requestPasswordReset,
  });
};
