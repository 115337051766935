import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Grid, Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { ToastContainer } from 'react-toastify';
import { object, string, ZodType } from 'zod';

import { Spinner } from '../../../components/Elements';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import { notifyError, notifySuccess } from '../../../utils/notifications';
import styles from '../../../utils/styles';
import { useSendForm } from '../api/sendForm';

export interface FormValues {
  full_name: string;
  email: string;
  phone_number: string;
  subject: string;
  message: string;
}

const subjectOptions = [
  { value: 'YouTube channel', label: 'YouTube channel' },
  { value: 'Live session', label: 'Live session' },
  { value: 'Pre-recorded videos', label: 'Pre-recorded videos' },
  { value: 'AwesumEdge website issues', label: 'AwesumEdge website issues' },
  { value: 'Collaboration', label: 'Collaboration' },
];

const UserSchema: ZodType<FormValues> = object({
  full_name: string().min(1, 'Full Name is required'),
  email: string()
    .min(1, 'Email is required')
    .email('Email format is not valid'),
  phone_number: string().min(1, 'Phone number is required'),
  subject: string().min(1, 'Subject is required'),
  message: string().min(1, 'Message is required'),
});

export const Form = () => {
  const form = useForm<FormValues>({
    resolver: zodResolver(UserSchema),
  });

  const {
    mutate: sendForm,
    isSuccess,
    isError,
    data,
    error,
    reset,
    isPending,
  } = useSendForm();

  const { register, control, handleSubmit, formState, reset: resetForm } = form;

  const { errors } = formState;

  const onSubmit = async (data: FormValues) => {
    sendForm(data);
  };

  if (isSuccess) {
    notifySuccess(data.data?.message, false);
    reset();
    resetForm();
  }

  if (isError) {
    notifyError(getErrorMsg(error as unknown as ErrorProps), false);
    reset();
  }

  return (
    <div className='sm:max-w-[500px] mt-8'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formControl}>
          <label htmlFor='fullName' className={styles.label}>
            Full Name*
          </label>
          <input
            className='w-full'
            type='text'
            id='fullname'
            {...register('full_name', {
              required: {
                value: true,
                message: 'Full Name is required',
              },
            })}
          />
          <p className={styles.error}>{errors.full_name?.message}</p>
        </div>

        <div className={styles.formControl}>
          <label htmlFor='email' className={styles.label}>
            E-mail Address*
          </label>
          <input
            className='w-full'
            type='email'
            id='email'
            {...register('email', {
              pattern: {
                value:
                  /#$%%1332333%^&*()+_-=??><Box,,.;'*:[{=vfjdkvlcxlvm}]cvjf/,
                message: 'invalid email format',
              },
            })}
          />
          <p className={styles.error}>{errors.email?.message}</p>
        </div>

        <div className={styles.formControl}>
          <label htmlFor='number' className={styles.label}>
            Phone Number*
          </label>
          <input
            className='w-full'
            type='phone'
            id='number'
            {...register('phone_number', {
              required: {
                value: true,
                message: 'Phone Number is required',
              },
            })}
          />
          <p className={styles.error}>{errors.phone_number?.message}</p>
        </div>

        <div className={styles.formControl}>
          <label htmlFor='subject' className={styles.label}>
            Subject*
          </label>
          <Controller
            name='subject'
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Select
                  id='subject'
                  onChange={(
                    option: SingleValue<{ label: string; value: string }>,
                  ) => {
                    onChange(String(option?.value));
                  }}
                  options={subjectOptions}
                  placeholder=''
                  classNamePrefix='contactSubject'
                />
              );
            }}
          />

          <p className={styles.error}>{errors.subject?.message}</p>
        </div>

        <Grid container spacing={2} display={'flex'} flexDirection={'column'}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box width='100%'>
              <label htmlFor='message' className={styles.label}>
                Your Message*
              </label>
              <TextField
                id='message'
                multiline
                minRows={4}
                maxRows={8}
                fullWidth
                variant='outlined'
                {...register('message', {
                  required: {
                    value: true,
                    message: 'message is required',
                  },
                })}
                style={{
                  marginTop: '1rem',
                }}
              />
              <p className={styles.error}>{errors.message?.message}</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              data-cy='submit'
              variant='contained'
              style={{
                backgroundColor: 'rgba(253, 131, 35, 1)',
                borderRadius: '5px',
                width: '100%',
                marginBottom: '50px',
              }}
              type='submit'
              startIcon={isPending ? <Spinner variant='light' /> : null}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
      <DevTool control={control} />
      <ToastContainer />
    </div>
  );
};
