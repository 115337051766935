import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useMemo, useState } from 'react';

import { Spinner } from '../../../../components/Elements';
import { Head } from '../../../../components/Head';
import { Table, TableMenu } from '../../../../components/Table';
import { useGetStudents } from '../api';
import { ConfirmDelete } from '../components/ConfirmDelete';
import { EditStudent } from '../components/EditStudent';
import { Filter } from '../components/Filter';
import { ViewStudent } from '../components/ViewStudent';

export type Student = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country?: string;
};

export const Students = () => {
  const { data, isFetching } = useGetStudents();
  console.log('data', data);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

  const handleEdit = (data: Student) => {
    setSelectedStudent(data);
    setIsEditOpen(true);
  };

  const handleView = (data: Student) => {
    setSelectedStudent(data);
    setIsViewOpen(true);
  };

  const handleDelete = (data: Student) => {
    setSelectedStudent(data);
    setIsDeleteConfirmationOpen(true);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: 'User ID',
      //   accessor: 'id',
      // },
      {
        Header: 'Full Name',
        Cell: ({ row }: any) => {
          return `${row.original.first_name} ${row.original.last_name}`;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Country',
        Cell: ({ row }: any) => {
          return (
            <div className='flex items-center'>
              <span
                className={`fi fi-${row.original.country.toLowerCase()} mr-2`}
              ></span>
              {row.original.country}
            </div>
          );
        },
      },

      {
        Header: 'Action',
        id: 'menu',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div className=''>
            <TableMenu
              _id={row.original._id}
              handleEdit={() => handleEdit(row.original)}
              handleDelete={() => handleDelete(row.original)}
              handleView={() => handleView(row.original)}
            />
          </div>
        ),
      },
    ],
    [],
  );

  // const deleteStudent = (id: string) => {
  //   students = students.filter((student: Student) => student.id !== id);
  // };

  return isFetching ? (
    <div className='min-h-[50vh] flex flex-col  items-center justify-center'>
      <Spinner size='md' />
    </div>
  ) : (
    <div>
      <Head title='Students' />
      <div className=' flex justify-end'>
        <div className='text-[#667085] hover:text-white -mt-6'>
          <button
            // onClick={() => setIsFilterOpen(true)}
            className='flex items-center border-[#E0E2E7] hover:bg-primary  text-sm border-[1px] rounded-md px-4 py-2'
          >
            <AdjustmentsHorizontalIcon className='h-5 mr-1 ' />
            Filters
          </button>
        </div>
      </div>

      <div className='overflow-hidden '>
        <Table data={data ?? []} columns={columns} showPagination={true} />
      </div>

      <Modal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Filter />
      </Modal>

      <Modal
        open={isViewOpen}
        onClose={() => setIsViewOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <ViewStudent
          data={selectedStudent as Student}
          closeModal={() => setIsViewOpen(false)}
        />
      </Modal>

      <Modal
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <EditStudent
          data={selectedStudent as Student}
          closeModal={() => setIsEditOpen(false)}
        />
      </Modal>

      <ConfirmDelete
        isOpen={isDeleteConfirmationOpen}
        closeModal={() => setIsDeleteConfirmationOpen(false)}
        // deleteDocument={deleteStudent}
        //isLoading={isDeleteLoading}
        selectedData={selectedStudent}
      />
    </div>
  );
};
