import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Head } from '../../../components/Head';
import { PreviousSessions } from '../components/PreviousSessions';
import { UpcomingSessions } from '../components/UpcomingSessions';

export const LiveWorkshops = () => {
  return (
    <>
      <Head title='Live Workshops' />
      <div className='flex flex-col min-h-[50vh] px-8 py-8'>
        <Breadcrumbs page='Live Workshops' />

        <UpcomingSessions bgColor='#FFFFFF' />

        <PreviousSessions />
      </div>
    </>
  );
};
