import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  DocumentMagnifyingGlassIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Fragment } from 'react';

type TableMenuProps = {
  _id: string;
  handleDelete?: (_id: string) => void;
  handleEdit?: (_id: string) => void;
  handleView: (_id: string) => void;
};

export const TableMenu = ({
  _id,
  handleDelete,
  handleEdit,
  handleView,
}: TableMenuProps) => {
  return (
    <div className='text-left top-16 w-28'>
      <Menu as='div' className='inline-block text-left'>
        <div>
          <Menu.Button className='flex items-center justify-center w-8 h-8 bg-white shadow-md table-menu'>
            <EllipsisVerticalIcon className='h-5' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 z-10 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleView(_id)}
                    className={`${
                      active ? ' text-primary' : 'text-black'
                    } plan flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <DocumentMagnifyingGlassIcon
                      className='w-4 h-4 mr-2'
                      aria-hidden='true'
                    />
                    View
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleEdit && handleEdit(_id)}
                    className={`${
                      active ? ' text-primary' : 'text-black'
                    } plan flex w-full items-center rounded-md px-2 py-2 text-sm edit-btn`}
                  >
                    <PencilSquareIcon
                      className='w-4 h-4 mr-2'
                      aria-hidden='true'
                    />
                    Edit
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleDelete && handleDelete(_id)}
                    className={`${
                      active ? ' text-primary' : 'text-black'
                    } plan flex w-full items-center rounded-md px-2 py-2 text-sm delete-btn`}
                  >
                    <TrashIcon className='w-4 h-4 mr-2' aria-hidden='true' />
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
