import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Head } from '../../../components/Head';
import { PreviousSessions } from '../../liveworkshops/components/PreviousSessions';
import { UpcomingSessions } from '../../liveworkshops/components/UpcomingSessions';

export const Courses = () => {
  return (
    <>
      <Head title='Courses' />

      <div className='flex flex-col min-h-[50vh] px-8'>
        <Breadcrumbs page='Courses' />

        <UpcomingSessions bgColor='#FFFFFF' />

        <PreviousSessions />
      </div>
    </>
  );
};
