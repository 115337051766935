import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Head } from '../../../../components/Head';
import { useGetLiveSession } from '../api/getLiveSession';
import { LiveSessionForm } from '../components/LiveSessionForm';
import { ScheduleLiveSessionInput } from '../types';

export const ViewLiveSession: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ id });

  const [defaultValues, setDefaultValues] =
    useState<ScheduleLiveSessionInput | null>(null);
  const { data, isFetching } = useGetLiveSession(id as string);

  useEffect(() => {
    if (data) {
      setDefaultValues({
        ...data,
        image_id: data.image_id?.id,
        presigned_url: data.image_id?.presigned_url,
        category_id: data.category?.id,
        capacity: String(data?.capacity),
      });
    }
  }, [id, data]);

  if (!defaultValues || isFetching) return <div>Loading...</div>;

  const handleSubmit = (data: ScheduleLiveSessionInput) => {
    console.log(data);
  };

  const handleCancel = () => {
    navigate('/admin/live-sessions');
  };

  return (
    <>
      <Head title='View Live Session' />
      <LiveSessionForm
        defaultValues={defaultValues}
        mode='view'
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
