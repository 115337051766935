import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { calenderIcon } from '../../../assets';
import { Link, Spinner } from '../../../components/Elements';
import {
  convertTime,
  convertToAbbreviation,
  formatDate,
} from '../../../utils/converter';
import storage from '../../../utils/storage';
import { useStartLiveSession } from '../../admin/livesessions/api/startLiveSession';
import { LiveSessionProps } from '../../liveworkshops/types';
import { useGetUpcomingLiveSessions } from '../api';

import { UpcomingSessionPrice } from './UpcomingSessionPrice';

const Banner: React.FC<{ imgSrc: any; imgAlt: string }> = ({
  imgSrc,
  imgAlt,
}) => (
  <div className='h-[50%] relative xl:h-full xl:w-[50%]'>
    <img
      src={imgSrc}
      alt={imgAlt}
      className='w-full h-full absolute object-cover'
    />
  </div>
);

const Details: React.FC<{ data: LiveSessionProps }> = ({ data }) => {
  const navigate = useNavigate();
  const token = storage.getToken();
  const location = useLocation();
  const { mutate: startLiveSession, isPending } = useStartLiveSession();

  const [selectedLiveSession, setSelectedLiveSession] = useState('');

  const isAdminDashboard = location.pathname.includes('admin');
  const title =
    data.title.length > 38 ? data.title.substring(0, 38) + '...' : data.title;

  const studentCourses = !isAdminDashboard ? storage.getUser()?.courses : null;

  const handleEnrolBtn = (workshopID: string) => {
    if (token) {
      navigate(`/workshop-registration/${workshopID}`);
    } else {
      navigate(`/auth/login`, {
        state: `/workshop-registration/${workshopID}`,
      });
    }
  };

  return (
    <div
      data-cy='upcoming-session-details'
      className='h-[50%] bg-white xl:h-full xl:w-[50%] p-4 text-secondary'
    >
      <div
        className={`${isAdminDashboard ? 'h-[35%]' : 'h-[25%] md:h-[30%] lg:h-[20%] xl:h-[25%]'}  text-xl font-bold`}
      >
        {location.pathname.includes('workshop') ? (
          title
        ) : (
          <h3>{title}</h3>
          // <Link to={`/workshops/#${data.id}`}>{title}</Link>
        )}
      </div>
      <div
        className={`${isAdminDashboard ? 'h-[65%]' : 'h-[75%] md:h-[70%] lg:h-[80%] xl:h-[75%]'} `}
      >
        <div className='h-[60%] text-secondary font-semibold'>
          <div className='flex items-center mb-2 text-sm'>
            <img
              src={calenderIcon}
              alt='calender icon'
              className='w-4 h-4 mr-1 hidden lg:block'
            />
            {formatDate(new Date(data.start_date))} -{' '}
            {parseInt(data.duration_weeks)} Weeks
          </div>
          <div className='text-xs mb-2'>
            {data.class_days
              .map((day) => convertToAbbreviation(day))
              .join(' & ')}{' '}
            {convertTime(data.start_time)?.cstTime} (CST) |{' '}
            {convertTime(data.start_time)?.watTime} (WAT)
          </div>
          <div className='text-xs mb-2'>
            Duration: {parseInt(data.duration_hours)} Hours
          </div>
          <div className='text-sm mb-2'>
            <span>Price:</span> <UpcomingSessionPrice data={data} />
          </div>
        </div>
        {isAdminDashboard ? (
          <div className='h-[40%] flex flex-col items-center justify-end'>
            <button
              data-cy='submit'
              onClick={() => {
                setSelectedLiveSession(data.id);
                startLiveSession(data.id);
              }}
              type='button'
              disabled={isPending}
              className={`flex items-center justify-center bg-secondary hover:bg-primary text-white rounded-lg w-[90%] p-1 text-[14px] md:text-[16px] font-medium self-center h-[36px] disabled:bg-slate-400 disabled:hover-bg-slate-500`}
            >
              {isPending && selectedLiveSession === data.id && (
                <Spinner className='mr-2' size='sm' />
              )}
              Start Live Session
            </button>
          </div>
        ) : (
          <div className='h-[40%] flex flex-col items-center justify-end'>
            {!studentCourses?.includes(data.course_id) && (
              <div className='text-xs font-semibold text-red-500 mb-1 lg:mb-2 text-center'>
                Registration Deadline:{' '}
                {formatDate(new Date(data.registration_due_date))}
              </div>
            )}

            {studentCourses && studentCourses.includes(data.course_id) ? (
              <button
                data-cy='submit'
                onClick={() =>
                  navigate(`/student/my-courses/${data.course_id}`)
                }
                type='button'
                className={`bg-secondary hover:bg-primary text-white rounded-lg w-[90%] p-1 text-[14px] md:text-[16px] font-medium self-center h-[36px]`}
              >
                Go to Course
              </button>
            ) : (
              <button
                data-cy='submit'
                onClick={() => handleEnrolBtn(data.id)}
                type='button'
                className={`bg-secondary hover:bg-primary text-white rounded-lg w-[90%] p-1 text-[14px] md:text-[16px] font-medium self-center h-[36px]`}
              >
                Enroll Now
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const UpcomingLiveSession: React.FC<{
  data: LiveSessionProps;
  index: number;
  hasBorder: boolean;
}> = ({ data, index, hasBorder }) => {
  return (
    <div
      id={data.id}
      className={`${'xl:max-h-[300px] h-[540px]'}   upcoming-session  mb-4 md:flex-[0.5]  flex flex-col xl:flex-row ${hasBorder ? 'border-[#D9D9D9] border-[1px]' : ''} lg:${(index + 1) % 2 ? '' : 'ml-0 md:ml-4'}`}
    >
      <Banner imgSrc={data.image?.presigned_url} imgAlt={data.title} />
      <Details data={data} />
    </div>
  );
};

export const UpcomingSessions: React.FC<{ bgColor: '#F4F4F4' | '#FFFFFF' }> = ({
  bgColor,
}) => {
  const { data: upcomingLiveSessions, isLoading } =
    useGetUpcomingLiveSessions();
  const navigate = useNavigate();
  const location = useLocation();
  const showViewAllBtn = location.pathname === '/';
  const isAdminPage = location.pathname.includes('admin');

  return (
    <div
      className={`${bgColor === '#F4F4F4' ? 'px-8' : ''} pt-8 bg-[${bgColor}] min-h-[30vh]`}
    >
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between text-secondary'>
        <h3 className='mb-4 text-2xl sm:text-4xl font-bold '>
          Upcoming Live Sessions
        </h3>
        {showViewAllBtn && upcomingLiveSessions?.count ? (
          <Link
            className='hidden sm:block text-sm italic font-medium hover:text-primary'
            to='/workshops'
          >
            View all
          </Link>
        ) : null}
      </div>
      {isLoading && (
        <div className='min-h-[30vh] flex flex-col  items-center justify-center'>
          <Spinner size='md' />
        </div>
      )}
      <div className='flex flex-col w-full md:flex-row flex-wrap'>
        {upcomingLiveSessions &&
        upcomingLiveSessions.count &&
        showViewAllBtn ? (
          <>
            <UpcomingLiveSession
              hasBorder={bgColor === '#FFFFFF'}
              index={0}
              data={upcomingLiveSessions.results[0]}
            />
            {upcomingLiveSessions.results[1] && (
              <UpcomingLiveSession
                hasBorder={bgColor === '#FFFFFF'}
                index={1}
                data={upcomingLiveSessions.results[1]}
              />
            )}
          </>
        ) : null}
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 grid-template-columns: 1fr 1fr 1fr gap-2'>
        {!showViewAllBtn && upcomingLiveSessions && upcomingLiveSessions.count
          ? upcomingLiveSessions.results.map(
              (upcomingSession: LiveSessionProps, index: number) => (
                <UpcomingLiveSession
                  data={upcomingSession}
                  key={upcomingSession.id}
                  hasBorder={bgColor === '#FFFFFF'}
                  index={index}
                />
              ),
            )
          : null}
        {upcomingLiveSessions && upcomingLiveSessions.count === 0 && (
          <div className='flex flex-1 py-4'>
            <h3 className='text-slate-500 text-2xl font-semibold'>
              {isAdminPage ? 'No data to display' : 'Coming Soon...'}
            </h3>
          </div>
        )}
      </div>

      {showViewAllBtn ? (
        <button
          onClick={() => navigate(`/workshops`)}
          type='button'
          className={`sm:hidden bg-transparent hover:border-white hover:text-white border-primary border-[1px] text-primary rounded-lg w-full p-1 md: py-2 mb-4 text-[12px] md:text-[16px] font-medium`}
        >
          View all Upcoming Live Sessions
        </button>
      ) : null}
      <ToastContainer />
    </div>
  );
};
