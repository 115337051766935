import React, { SetStateAction, useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

export const BannerUploader = ({
  onImageUpload,
  initialUrl,
  isViewMode,
  isEditingContent,
}: any) => {
  const [selectedImage, setSelectedImage] =
    useState<SetStateAction<string | null>>(null);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        setSelectedImage(URL.createObjectURL(file));

        const formData = new FormData();
        formData.append('file', file);
        onImageUpload(formData);
      }
    },
    [onImageUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*' as unknown as Accept,
    noClick: true,
  });

  return (
    <div
      {...(!isViewMode || !isEditingContent ? getRootProps() : {})}
      className={`mt-4 w-full min-h-40 bg-[#F2F2F2] rounded-md flex flex-col items-center justify-center 
                    ${isDragActive ? 'border-orange-500' : 'border-gray-300'}`}
    >
      {!isViewMode && !isEditingContent && <input {...getInputProps()} />}

      <div className='flex flex-col items-center w-full px-2'>
        {initialUrl || selectedImage ? (
          <div className='w-full min-h-20 justify-center flex '>
            <img
              src={(selectedImage as string) ?? initialUrl}
              alt='selected'
              className='object-cover overflow-hidden max-h-[400px] rounded-md'
            />
          </div>
        ) : (
          <>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
              />
            </svg>
            <p className='text-gray-400'>
              Drag and drop images here or click to select files
            </p>
          </>
        )}

        <button
          type='button'
          className='mt-4 p-2 bg-[#FED6B8] text-primary rounded-md text-sm'
          onClick={() =>
            (
              document.querySelector('input[type="file"]') as HTMLElement
            ).click()
          }
          disabled={isViewMode || isEditingContent}
        >
          {initialUrl || selectedImage ? '+ Change Image' : '+ Add Image'}
        </button>
      </div>
    </div>
  );
};
