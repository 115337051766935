import { Box, Typography } from '@mui/material';

import { OpeyemiOlanipekun } from '../../../assets';

export const TheVisionary = () => {
  return (
    <Box
      marginBottom='4rem'
      display='flex'
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <div className='flex-[0.3] flex 2xl:justify-end'>
        <img
          id='visionary-lg'
          src={OpeyemiOlanipekun}
          alt='visionary'
          className='rounded-md'
          style={{ width: '100%', maxWidth: '400px', height: 'auto' }}
        />
      </div>

      <Typography
        className='flex-[0.7] flex flex-col justify-center'
        marginLeft={{
          md: '15px',
        }}
      >
        <Typography
          fontSize={'clamp(24px, 2vw, 48px)'}
          fontWeight={'bolder'}
          marginBottom='10px'
          color={'rgba(13, 6, 95, 1)'}
        >
          The Visionary
        </Typography>
        <img
          id='visionary-sm'
          className='rounded-md'
          src={OpeyemiOlanipekun}
          alt='visionary'
          style={{
            width: '100%',
            maxWidth: '400px',
            height: 'auto',
            marginBottom: '16px',
          }}
        />
        <Typography color={'rgba(79, 79, 79, 1)'}>
          At the heart of our company stands a visionary leader whose passion,
          perseverance and dedication have propelled us forward on our mission
          to revolutionize education. <br /> <br /> Meet Dr. Opeyemi Olanipekun,
          the driving force behind AwesumEdge. With a strong commitment to
          innovation and profound belief in the power of education to transform
          lives, he is committed to changing and positively impacting billion
          lives. <br /> <br /> Dr. Olanipekun has got a rich and diverse
          background that cuts across teaching, quality control, process
          engineering, research and development, supply chain engineering, data
          engineering, data science and machine learning engineering in the food
          and beverage, semiconductor and e-commerce sectors.
        </Typography>
      </Typography>
    </Box>
  );
};
