import React from 'react';

import { logo } from '../../../assets';
import { Link } from '../../../components/Elements';

const Header: React.FC<{ title: string; subTitle: string }> = ({
  title,
  subTitle,
}) => {
  return (
    <div className='flex flex-col items-center'>
      <Link to='/'>
        <img src={logo} alt='AwesumEdge Logo' className='w-[128px]' />
      </Link>

      <h1 className='text-2xl sm:text-3xl font-semibold text-secondary my-2'>
        {title}
      </h1>
      <p className='text-slate-400 text-sm sm:text-md text-center'>
        {subTitle}
      </p>
    </div>
  );
};

export default Header;
