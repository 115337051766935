import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Spinner } from '../../../components/Elements';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import { notifyError, notifySuccess } from '../../../utils/notifications';
import { useResendEmail } from '../api/register';
import Header from '../components/Header';

export const RegistrationSuccess = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    mutate: resendEmail,
    isPending,
    isSuccess,
    isError,
    data,
    error,
    reset,
  } = useResendEmail();

  useEffect(() => {
    if (!state) {
      navigate('/');
    }
  });

  if (isSuccess) {
    notifySuccess(data.data?.message, false);
    reset();
  }

  if (isError) {
    notifyError(getErrorMsg(error as unknown as ErrorProps), false);
    reset();
  }

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        height: '100vh',
        color: 'rgba(13, 6, 95, 1)',
      }}
    >
      <Header title='We Sent You an Email' subTitle='' />

      <Typography variant='body1'>
        An <span style={{ fontWeight: 'bolder' }}>Account Verification </span>
        email has been sent to{' '}
        <span style={{ fontWeight: 'bolder' }}>{state?.email}</span>.
      </Typography>
      <Typography variant='h5' sx={{ paddingTop: '25px' }}>
        Click the link in the email to complete your account creation.
      </Typography>
      <Typography display={'flex'} sx={{ paddingTop: '25px' }}>
        Don’t see an email from us?
        <Typography sx={{ color: 'rgba(0, 122, 255, 1)', paddingLeft: '10px' }}>
          {isPending ? (
            <Spinner size='sm' />
          ) : (
            <button
              data-cy='resend-email'
              className=''
              onClick={() => resendEmail(state?.email)}
            >
              Resend Email
            </button>
          )}
        </Typography>
      </Typography>
      <Typography display={'flex'} sx={{ paddingTop: '25px' }}>
        Need help?
        <Typography sx={{ color: 'rgba(0, 122, 255, 1)', paddingLeft: '10px' }}>
          <a href='/contact'>Contact Us</a>
        </Typography>
      </Typography>
      <ToastContainer />
    </Box>
  );
};
