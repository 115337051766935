import React from 'react';

import { Link } from '../Elements';

export const Breadcrumbs: React.FC<{ page: string }> = ({ page }) => {
  return (
    <div className='min-h-[70px] bg-secondary rounded-md flex items-center p-2'>
      <Link to='/' className='text-slate-400 text-sm mr-1 hover:text-slate-500'>
        Home/
      </Link>

      <span className='text-primary text-xl font-semibold'>{page}</span>
    </div>
  );
};
