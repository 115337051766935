import React from 'react';

import { codes } from '../../../assets';

export const WhatWeDo = () => {
  return (
    <div className='mt-8 sm:mt-0 mb-8 sm:my-0 sm:py-8 flex'>
      <div className='flex flex-col flex-1 sm:flex-[0.6] justify-center'>
        <h2 className='text-2xl sm:text-3xl font-semibold text-start mb-4 text-secondary'>
          What We Do
        </h2>
        <div className='sm:hidden'>
          <img alt='codes' src={codes} className='w-full mb-4 rounded-md' />
        </div>
        <p className='text-[#4F4F4F] pr-0 sm:pr-4'>
          AwesumEdge is an e-learning platform where students can access
          thousands of pre-recorded instructional videos with lots of practice
          problem sets anytime and anywhere to better prepare them for career
          success.
          <br /> <br /> We also provide live session bootcamps in the data
          engineering, data science & machine learning domain, and students
          would have access to the live session recorded video in order to
          revise what was taught in class.
        </p>
      </div>
      <div className='hidden sm:flex flex-[0.4] justify-end'>
        <img
          alt='codes'
          src={codes}
          className='mt-16 md:mt-0 h-[250px] md:h-[350px] rounded-md'
        />
      </div>
    </div>
  );
};
