import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { calenderIcon, flutterwave, paystack, stripe } from '../../../assets';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Button } from '../../../components/Elements';
import { Head } from '../../../components/Head';
import { ErrorProps } from '../../../types';
import {
  convertTime,
  convertToAbbreviation,
  formatDate,
} from '../../../utils/converter';
import getErrorMsg from '../../../utils/getErrorMsg';
import { notifyError } from '../../../utils/notifications';
import styles from '../../../utils/styles';
import { useGetLiveSession } from '../../admin/livesessions/api/getLiveSession';
import { useGeneratePaymentURL, useVerifyPayment } from '../api';
import { Loader } from '../components/Loader';
import { RegistrationSuccess } from '../components/RegistrationSuccess';
import { UpcomingSessionPrice } from '../components/UpcomingSessionPrice';
import { Question, RegistrationInput, registrationSchema } from '../types';

export const Registration = () => {
  const [showModal, setShowModal] = useState(false);
  const { search } = useLocation();
  const params = useParams();

  const { id } = params;
  const { data, isFetching } = useGetLiveSession(id as string);

  const transRef =
    search.split('?trxref=')[1]?.split('&')[0] ??
    search.split('&tx_ref=')[1]?.split('&')[0];

  const {
    data: verificationData,
    isLoading: isVerificationLoading,
    error: paymentVerificationError,
    isError: isPaymentVerificationError,
    isSuccess: isPaymentVerificationSuccess,
  } = useVerifyPayment(transRef);

  const {
    mutate: generatePaymentURL,
    isPending: isGeneratePaymentURLPending,
    isSuccess: isGeneratePaymentURLSuccess,
    isError: isGeneratePaymentURLError,
    error: generatePaymentURLError,
    data: generatePaymentURLData,
    reset: resetGeneratePaymentURL,
  } = useGeneratePaymentURL();

  useEffect(() => {
    if (
      isPaymentVerificationSuccess &&
      verificationData &&
      verificationData.status === 'completed'
    ) {
      setShowModal(true);
    }

    if (isPaymentVerificationError) {
      notifyError(
        getErrorMsg(paymentVerificationError as unknown as ErrorProps),
        5000,
      );
    }
  }, [
    transRef,
    verificationData,
    isPaymentVerificationSuccess,
    isPaymentVerificationError,
    paymentVerificationError,
  ]);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm<RegistrationInput>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {
      live_session: id,
      questions: [],
      payment_provider: 'paystack',
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });

  useEffect(() => {
    if (data) {
      setValue('questions', data.questions);
      data.questions.forEach((question: Question, index: number) => {
        setValue(`questions.${index}.question_id`, question.id);
      });
    }
  }, [data, setValue]);

  const onSubmit = (data: RegistrationInput) => {
    const answers = data.questions.map((question) => ({
      question: question.question_id,
      answer_text: question.answer_text,
    }));

    const payload = {
      live_session: data.live_session,
      payment_provider: data.payment_provider,
      answers,
    };

    generatePaymentURL(payload);
  };

  if (isGeneratePaymentURLSuccess) {
    if (
      generatePaymentURLData.data.message ===
      'Registration successful. No payment required.'
    ) {
      setShowModal(true);
    } else {
      const paymentURL = generatePaymentURLData.data.payment_url;

      if (paymentURL) {
        window.location.href = paymentURL;

        //window.open(paymentURL);
      }
    }

    resetGeneratePaymentURL();
  }

  if (isGeneratePaymentURLError) {
    notifyError(
      getErrorMsg(generatePaymentURLError as unknown as ErrorProps),
      false,
    );
    resetGeneratePaymentURL();
  }

  if (isFetching && !data) {
    return <div className='m-8 min-h-[40vh]'>Loading...</div>;
  }

  return (
    <div className='px-8'>
      <Head title='Live Workshop Registration' />

      <div className='flex flex-col min-h-[50vh] mb-16'>
        <Breadcrumbs page='Live Session Registration' />

        <div className='text-xl font-bold my-4 text-secondary '>
          {data.title}
        </div>
        <div className='flex flex-col sm:flex-row justify-between text-sm 2xl:text-md font-medium text-secondary max-w-[600px]'>
          <div className='flex flex-col'>
            <div className='flex items-center mb-2 text-sm'>
              <img
                src={calenderIcon}
                alt='calender icon'
                className='w-4 h-4 mr-1 hidden lg:block'
              />
              {formatDate(new Date(data.start_date))} -{' '}
              {parseInt(data.duration_weeks)} Weeks
            </div>
            <div className='text-xs mb-2'>
              {data.class_days
                .map((day: string) => convertToAbbreviation(day))
                .join(' & ')}{' '}
              {convertTime(data.start_time)?.cstTime} (CST) |{' '}
              {convertTime(data.start_time)?.watTime} (WAT)
            </div>
            <div className='text-xs mb-2'>
              Duration: {parseInt(data.duration_hours)} Hours
            </div>
          </div>

          <div className='flex flex-col'>
            <div className='text-sm mb-2'>
              <span>Price:</span> <UpcomingSessionPrice data={data} />
            </div>

            <div className='text-sm mb-2'>
              <span>Discount Applied:</span>{' '}
              <span className='font-bold'>{data.discount_percentage}%</span>
            </div>

            <div className='text-xs font-semibold text-red-800 mb-1 lg:mb-2'>
              Registration Deadline:{' '}
              {formatDate(new Date(data.registration_due_date))}
            </div>
          </div>
        </div>
        <div className=' text-sm 2xl:text-md font-medium text-secondary '></div>

        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' {...register('live_session')} />

          {fields.map((field, index) => {
            return field.question_text ? (
              <div key={field.id} className={styles.formControl}>
                <label
                  htmlFor={`questions.${index}.answer_text`}
                  className={styles.label}
                >
                  {field.question_text}
                </label>
                <input
                  data-cy={`questions.${index}.answer_text`}
                  {...register(`questions.${index}.answer_text`)}
                  placeholder={`Answer for ${field.question_text}`}
                  type='text'
                  className={styles.input}
                />
                <p className={styles.error}>
                  {errors.questions?.[index]?.answer_text?.message as string}
                </p>
              </div>
            ) : null;
          })}

          {Number(data.discount_percentage) < 100 ? (
            <div className={styles.formControl}>
              <label htmlFor='payment_provider' className={styles.label}>
                Payment Method
              </label>
              <Controller
                name='payment_provider'
                control={control}
                //defaultValue='paystack'
                render={({ field }) => (
                  <>
                    <div className='flex mt-1'>
                      <input
                        data-cy='stripe'
                        {...field}
                        type='radio'
                        value='stripe'
                        id='stripe'
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <label htmlFor='stripe'>
                        <img src={stripe} className='w-16' alt='stripe' />
                      </label>
                    </div>

                    <div className='flex mt-4'>
                      <input
                        data-cy='paystack'
                        {...field}
                        type='radio'
                        value='paystack'
                        id='paystack'
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        defaultChecked
                      />
                      <label htmlFor='paystack'>
                        <img src={paystack} className='w-32' alt='paystack' />
                      </label>
                    </div>

                    {/* <div className='flex mt-4'>
                      <input
                        data-cy='flutterwave'
                        {...field}
                        type='radio'
                        value='flutterwave'
                        id='flutterwave'
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <label htmlFor='flutterwave'>
                        <img
                          src={flutterwave}
                          className='w-32'
                          alt='flutterwave'
                        />
                      </label>
                    </div> */}
                  </>
                )}
              />
              <p className={styles.error}>
                {errors.payment_provider?.message as string}
              </p>
            </div>
          ) : (
            <input type='hidden' {...register('payment_provider')} />
          )}

          <div className='my-8 flex justify-start'>
            <Button
              data-cy='submit'
              type='submit'
              variant='primary'
              isLoading={isGeneratePaymentURLPending}
            >
              {Number(data.discount_percentage) < 100
                ? 'Proceed to make payment'
                : 'Enroll'}
            </Button>
          </div>
        </form>
      </div>
      <RegistrationSuccess isOpen={showModal} />
      <ToastContainer />
      {isVerificationLoading && <Loader isLoading={isVerificationLoading} />}
    </div>
  );
};
