const videoID = 'RILztn0foPU';

export const YoutubeChannel = () => {
  return (
    <div className='px-8 pt-8 mt-8 min-h-[40vh] youtubeChannel-bg'>
      <div className='sm:max-w-[64vw] md:max-w-[72vw] mx-auto'>
        <h3 className='mb-4 text-2xl sm:text-3xl font-bold text-center text-secondary'>
          Checkout Our Youtube Channel for Free Resources
        </h3>

        <div className='w-full relative min-h-[30vh]'>
          <>
            <img
              src={`https://i.ytimg.com/vi_webp/${videoID}/maxresdefault.webp`}
              alt='YouTube video thumbnail'
              className='w-full rounded-md'
            />
            <div className='absolute inset-0 flex items-center justify-center'>
              <iframe
                className='w-full h-full absolute top-0 left-0'
                src={`https://www.youtube.com/embed/${videoID}`}
                title='YouTube video player'
                allowFullScreen
              ></iframe>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
