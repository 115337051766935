import { useNavigate } from 'react-router-dom';

import { Head } from '../../../../components/Head';
import { PreviousSessions } from '../../../liveworkshops/components/PreviousSessions';
import { UpcomingSessions } from '../../../liveworkshops/components/UpcomingSessions';
import { OngoingSessions } from '../components/OngoingSessions';

export const LiveSessions = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Head title='Live Sessions' />
      <div className='flex justify-end -mt-6'>
        <button
          onClick={() => navigate('/admin/schedule-live-session')}
          className='hover:bg-secondary bg-primary text-white font-semibold text-sm border-[1px] rounded-md px-4 py-2'
        >
          Schedule Live Session
        </button>
      </div>

      <UpcomingSessions bgColor='#FFFFFF' />

      <div className=''>
        <OngoingSessions />
      </div>

      <div className=''>
        <PreviousSessions />
      </div>
    </div>
  );
};
