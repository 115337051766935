import React from 'react';
import { useNavigate } from 'react-router-dom';

import { pc, workshop } from '../../../assets';

export const Offer = () => {
  const navigate = useNavigate();
  return (
    <div className='px-8 py-8 offer-bg'>
      <h3 className='mb-4 text-2xl sm:text-4xl font-bold text-secondary'>
        We Offer More Than Just
        <br className='hidden md:block' /> Courses
      </h3>

      <div className='min-h-[30vh] bg-white  flex flex-col md:flex-row flex-1 '>
        <div className='md:flex-[0.5] md:mr-4'>
          <div className='min-h-[300px] w-full bg-secondary flex flex-col md:flex-row  flex-1 p-4 items-center'>
            <div className='lg:flex-[0.6] text-white'>
              <h4 className='mb-2 text-xl sm:text-2xl font-bold'>
                Amazing Courses
              </h4>
              <p className='mb-8 text-grey'>
                Choose from a variety of expertly crafted courses designed to
                help you achieve your learning goals.
              </p>

              <button
                onClick={() => navigate(`/courses`)}
                type='button'
                className={`bg-transparent hover:border-white hover:text-white border-primary border-[1px] text-primary rounded-lg w-32 p-1 md: py-2 mb-4 text-[14px] md:text-[16px] font-medium`}
              >
                Explore
              </button>
            </div>

            <div className='lg:flex-[0.4] flex items-center'>
              <img src={pc} alt='PC' className='w-32 md:w-full' />
            </div>
          </div>

          <div className='mt-4 min-h-[300px] w-full bg-[#5145DB] flex flex-col md:flex-row  flex-1 p-4 items-center'>
            <div className='lg:flex-[0.6] text-white'>
              <h4 className='mb-2 text-xl sm:text-2xl font-bold'>Resources</h4>
              <p className='mb-8 text-grey'>
                Access a wealth of supplementary resources to enhance your
                learning experience.
              </p>

              <a target='blank' href='https://www.youtube.com/@tuplespectra'>
                <button
                  type='button'
                  className={`bg-transparent hover:border-white hover:text-white border-primary border-[1px] text-primary rounded-lg w-32 p-1 md: py-2 mb-4 text-[14px] md:text-[16px] font-medium`}
                >
                  Explore
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className='md:flex-[0.5] md:ml-4 flex flex-col bg-transparent justify-between'>
          <div className='min-h-[450px] w-full bg-[#342AA9] mt-4 md:mt-0 flex flex-col items-center justify-center p-4'>
            <h4 className='mb-2 text-xl sm:text-2xl font-bold text-white'>
              Live Workshops
            </h4>
            <p className='text-grey'>
              Join our live workshops led by industry experts to gain practical
              skills and insights.
            </p>
            <button
              onClick={() => navigate(`/workshops`)}
              type='button'
              className={`bg-transparent hover:border-white hover:text-white border-primary border-[1px] text-primary rounded-lg w-32 p-1 md: py-2 my-4 text-[14px] md:text-[16px] font-medium`}
            >
              Explore
            </button>

            <img src={workshop} alt='live workshops' className='w-[250px]' />
          </div>
          <div className='mt-4 min-h-[70px] sm:min-h-[150px] text-[#4F4F4F]'>
            Learn from the best instructors in the industry and take your skills
            to the next level with our live sessions.
          </div>
        </div>
      </div>
    </div>
  );
};
