import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { axios } from '../../../lib/axios';
import storage from '../../../utils/storage';
import { LoginInput, LoginResponse } from '../types';

import { getProfile } from './userprofile';

const login = async (payload: LoginInput) => {
  const response = await axios.post('/user/login', payload);
  return response.data;
};

const googleLogin = async (googleAccessToken: string) => {
  const response = await axios.post('/user/google-login/', {
    token: googleAccessToken,
  });
  return response.data;
};

const handleLoginSuccess = async (resp: LoginResponse, cb: () => void) => {
  const { status, data } = resp;

  if (status === 'success' && data?.token) {
    storage.setToken(data.token);
    const userResp = await getProfile();

    const { data: userData } = userResp;

    if (userData) {
      storage.setUser({
        ...userData,
        role: userData.is_staff ? 'ADMIN' : 'STUDENT',
      });
      cb();
    }
  }
};

export const useLogin = () => {
  const navigate = useNavigate();

  const { state, key, pathname } = useLocation();

  return useMutation({
    mutationFn: login,
    onSuccess: async (resp) => {
      handleLoginSuccess(resp, () =>
        navigate(
          state === 'reset-password'
            ? '/'
            : state ||
                (key && key !== 'default' && !pathname.includes('auth')
                  ? -1
                  : '/'),
        ),
      );
    },
  });
};

export const useGoogleLogin = () => {
  const navigate = useNavigate();
  const { state, key, pathname } = useLocation();

  return useMutation({
    mutationFn: googleLogin,
    onSuccess: async (resp) => {
      handleLoginSuccess(resp, () =>
        navigate(
          state ||
            (key && key !== 'default' && !pathname.includes('auth') ? -1 : '/'),
        ),
      );
    },
  });
};
