import { TypeOf, any, array, number, object, preprocess, string } from 'zod';

import { LiveSessionProps } from '../../../liveworkshops/types';

export const scheduleLiveSessionSchema = object({
  title: string().min(1, 'Class title is required'),
  category_id: string({ required_error: 'Category is Required' }),
  duration_hours: preprocess(
    (value) => (value === '' ? undefined : Number(value)),
    number({
      required_error: 'Duration in hours is required', // Custom required error
      invalid_type_error: 'Duration in hours must be a number',
    }).min(1, 'Duration in hours must be at least 1 hour'),
  ),
  duration_weeks: preprocess(
    (value) => (value === '' ? undefined : Number(value)),
    number({
      required_error: 'Duration in weeks is required', // Custom required error
      invalid_type_error: 'Duration in weeks must be a number',
    }).min(1, 'Duration in weeks must be at least 1 hour'),
  ),
  registration_due_date: string().min(1, 'Registration due date is required'),
  start_date: string().min(1, 'Start date is required'),
  class_days: array(string()).min(1, 'Class days is required'),
  start_time: string().min(1, 'Class time is required'),
  capacity: preprocess((value) => {
    if (value === '' || value === undefined) return undefined;
    const num = Number(value);
    return isNaN(num) ? undefined : num;
  }, number().positive('Capacity must be a positive number').optional()),
  about: string().min(1, 'About is required'),
  expiration_date: string().optional(),
  price_usd: number({ invalid_type_error: 'Please enter a number' }).min(
    1,
    'Base price is required',
  ),
  vat_percentage: preprocess((value) => {
    if (value === '' || value === undefined) return undefined;
    const num = Number(value);
    return isNaN(num) ? undefined : num;
  }, number().positive('VAT must be greater than 0').optional()),
  discount_percentage: preprocess((value) => {
    if (value === '' || value === undefined) return undefined;
    const num = Number(value);
    return isNaN(num) ? undefined : num;
  }, number().positive('Discount must be greater than 0').optional()),
  image_id: string({
    required_error: 'Banner is required',
  }).min(1),
  presigned_url: string().optional(),
  questions: array(
    object({
      //type: string(),
      question_text: string(),
      //options: array(string()).optional(),
    }),
  ).optional(),
  sections: array(
    object({
      title: string().min(1, 'Module title is required'),
      //objective: string().min(1, 'Module objective is required'),
      order: number({ required_error: 'Order is required' }),
      resources: array(
        object({
          title: string().min(1, 'Section content title is required'),
          uploadContent: any().optional(),
          file_type: string().optional(),
          presigned_url: string().optional(),
          file_key: string().optional(),
          file_url: string().optional(),
        }),
      ), //.optional(),
    }),
  ), //.optional(),
  course_id: string().optional(),
});

export type ScheduleLiveSessionInput = TypeOf<typeof scheduleLiveSessionSchema>;

export type ScheduleLiveSessionDefaultValues = {
  title?: string;
  category_id?: string;
  duration_hours?: number;
  duration_weeks?: number;
  registration_due_date?: string;
  start_date?: string;
  class_days: string[];
  start_time?: string;
  capacity?: number;
  about?: string;
  expiration_date?: string;
  price_usd?: number;
  vat_percentage?: number;
  discount_percentage?: number;
  image_id?: string;
  presigned_url?: string;
  course_id?: string;
  sections?: any;
};

export type Resource = {
  id: string;
  title: string;
  order: number;
  file_key: string;
  file_url: string;
  file_type: string;
  presigned_url: string;
};

export type Section = {
  id: string;
  title: string;
  order: number;
  course: string;
  resources: Resource[];
  section_id: string;
};

export type Course = {
  id: string;
  title: string;
  description: string;
  live_session: string;
  created_by: string;
  sections: Section[];
};

export type LiveSessionWithSections = LiveSessionProps & {
  sections: Section[];
};

export type LiveSessionDetailsResp = {
  status: string;
  code: number;
  data: LiveSessionWithSections;
};

export type Category = {
  id: string;
  name: string;
  description: string;
};

export type LiveSessionResp = {
  status: string;
  code: number;
  data: {
    count: number;
    results: LiveSessionProps[];
  };
};

export type MediaType =
  // Videos
  | 'video/mp4'
  | 'video/quicktime'
  | 'video/x-msvideo'
  | 'video/x-matroska'
  | 'video/x-ms-wmv'
  | 'video/webm'
  // Audio
  | 'audio/mpeg'
  | 'audio/wav'
  | 'audio/ogg'
  | 'audio/mp4'
  | 'audio/webm'
  | 'audio/x-aac'
  // Documents
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  // Archives
  | 'application/zip'
  | 'application/x-rar-compressed'
  | 'application/x-tar'
  | 'application/x-7z-compressed'
  | 'application/gzip'
  | 'application/x-bzip2'
  // Text
  | 'text/plain'
  | 'text/csv'
  | 'text/html'
  | 'text/xml'
  | 'text/css'
  | 'text/javascript'
  // Application-specific
  | 'application/json'
  | 'application/xml'
  | 'application/rtf'
  | 'application/x-sh'
  | 'application/x-www-form-urlencoded'
  | 'application/vnd.oasis.opendocument.text'
  | 'application/vnd.oasis.opendocument.spreadsheet';
