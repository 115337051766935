import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { userIcon } from '../../../../assets';
import { Spinner } from '../../../../components/Elements';
import { notifyError, notifySuccess } from '../../../../utils/notifications';
import { ResourceDetails, ResourceProgress } from '../../types';
import { useMarkResourceAsCompleted } from '../api/courseProgress';

import { Review } from './Review';

const REVIEWS = [
  {
    name: 'Ronald Richards',
    imgSrc: userIcon,
    review:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure ',
    rating: 4,
  },
  {
    name: 'Ronald Richards',
    imgSrc: userIcon,
    review:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure ',
    rating: 5,
  },
  {
    name: 'Ronald Richards',
    imgSrc: userIcon,
    review:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure ',
    rating: 4.5,
  },
  {
    name: 'Ronald Richards',
    imgSrc: userIcon,
    review:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Duis aute irure ',
    rating: 5,
  },
];

type DetailsProps = {
  openResourceDetails: ResourceDetails;
  openResourceProgress: ResourceProgress;
  courseDescription: string;
  courseID: string;
  sectionID: string;
};

export const getTabStyle = (isActive: boolean) => {
  return `${
    isActive
      ? 'border-black text-black border-b-[2px]'
      : 'text-slate-400 border-b-[1px]'
  } pr-2 sm:pr-4 text-sm sm:text-md pb-2 outline-none font-semibold`;
};

export const formatDescription = (description: string) => {
  return description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const Details = ({
  openResourceDetails,
  openResourceProgress,
  courseDescription,
  courseID,
  sectionID,
}: DetailsProps) => {
  const {
    mutate: markResourceAsCompleted,
    isPending,
    isSuccess,
    isError,
    reset,
  } = useMarkResourceAsCompleted(courseID, sectionID, openResourceDetails?.id);

  useEffect(() => {
    if (isSuccess) {
      notifySuccess('Resource marked as completed successfully', 3000);

      reset();
    }
    if (isError) {
      notifyError('An error occured, please try again', false);
      reset();
    }
  }, [isPending]);

  if (!openResourceDetails) {
    return null;
  }

  return (
    <div className='mt-4'>
      <div className='flex justify-between'>
        <h3 className='font-semibold text-xl'>{openResourceDetails.title}</h3>
        {openResourceProgress && !openResourceProgress.viewed && (
          <button
            onClick={() => markResourceAsCompleted(openResourceDetails.id)}
            className='bg-slate-700 flex items-center justify-center px-2 py-1 text-xs text-white hover:bg-primary rounded-xl'
          >
            {isPending && (
              <Spinner variant='light' className='mr-2' size='sm' />
            )}
            Mark as Completed
          </button>
        )}
      </div>

      <TabGroup className='mt-5'>
        <TabList className='flex p-1 border-slate-300 border-b-[0px]'>
          <Tab
            key='Course Details'
            className={({ selected }) => {
              return getTabStyle(selected);
            }}
          >
            COURSE DETAILS
          </Tab>
          <Tab
            key='Reviews'
            className={({ selected }) => {
              return selected === true
                ? 'active-tab ' + getTabStyle(selected)
                : getTabStyle(selected);
            }}
          >
            REVIEWS{' '}
            {/* <span
              id='review-count'
              className={`px-1 bg-slate-300 text-white rounded-full text-xs`}
            >
              23
            </span> */}
          </Tab>
        </TabList>
        <TabPanels className='mt-2'>
          <TabPanel key={'Course Details'} className={``}>
            <h4 className='font-semibold mb-2'>About This Course</h4>
            <p className='text-[15px]'>
              {formatDescription(courseDescription)}
            </p>
          </TabPanel>

          <TabPanel key={'Reviews'} className={``}>
            No data to display
            {/* {REVIEWS.map(({ name, review, imgSrc, rating }) => (
              <Review
                key={name}
                name={name}
                review={review}
                imgSrc={imgSrc}
                rating={rating}
              />
            ))} */}
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <ToastContainer />
    </div>
  );
};
