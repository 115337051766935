const styles = {
  formControl: 'mb-4 sm:mb-6',
  label: 'block text-sm 2xl:text-md font-medium text-slate-500 mb-1',
  input:
    'p-2 w-full border-slate-300 border-[1px] rounded-md shadow-all-sides outline-none',
  error: 'mt-1 text-xs text-red-400 font-bold',
  textarea:
    'text-sm text-slate-600 w-[100%] h-[132px] border-[1px] border-slate-200 rounded outline-none px-2',
};

export default styles;
