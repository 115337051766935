import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import { ErrorProps } from '../../../../types';
import { notifyError, notifySuccess } from '../../../../utils/notifications';
import { LiveSessionProps } from '../../../liveworkshops/types';
import { LiveSessionResp } from '../types';

const completeLiveSession = async (liveSessionID: string) => {
  const resp = await axios.patch(`/sessions/live/${liveSessionID}/`, {
    status: 'completed',
  });
  return resp.data;
};

export const useCompleteLiveSession = () => {
  const queryClient = useQueryClient();
  const ongoingLiveSessions: LiveSessionResp | undefined =
    queryClient.getQueryData(['ongoing-live-sessions']);

  const previousLiveSessions: LiveSessionResp | undefined =
    queryClient.getQueryData(['previous-live-sessions']);

  return useMutation({
    mutationFn: completeLiveSession,
    onSuccess: (data) => {
      try {
        const updatedOngoingSessions = {
          ...ongoingLiveSessions,
          data: {
            count: Number(ongoingLiveSessions?.data?.count) - 1,
            results: ongoingLiveSessions?.data.results.filter(
              (upcomingSession: LiveSessionProps) =>
                data.data.id !== upcomingSession.id,
            ),
          },
        };

        const updatedPreviousSessions = {
          ...previousLiveSessions,
          data: {
            count: Number(previousLiveSessions?.data?.count) + 1,
            results: previousLiveSessions
              ? [data.data, ...previousLiveSessions.data.results]
              : [data.data],
          },
        };

        queryClient.setQueryData(
          ['ongoing-live-sessions'],
          updatedOngoingSessions,
        );

        queryClient.setQueryData(
          ['previous-live-sessions'],
          updatedPreviousSessions,
        );

        notifySuccess('Live Session completed successfully', 3000);
      } catch (error) {
        console.log(error);
        notifyError('Something went wrong. Please try again', false);
      }
    },
    onError: (error: ErrorProps) => {
      notifyError(
        error.response?.data.message ??
          (error.message || 'Something went wrong. Please try again'),
        false,
      );
    },
  });
};
