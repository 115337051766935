import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { TypeOf, object, string } from 'zod';

import { Button } from '../../../../components/Elements';
import styles from '../../../../utils/styles';
import { Student } from '../routes/Students';

export const editStudentSchema = object({
  full_name: string().min(1, 'Full name is required'),
  email: string().email().min(1, 'Email is Required'),
  phone: string().min(1, 'Phone number is required'),
});

export type EditStudentInput = TypeOf<typeof editStudentSchema>;

export const EditStudent: React.FC<{ data: Student; closeModal: any }> = ({
  data,
  closeModal,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<EditStudentInput>({
    resolver: zodResolver(editStudentSchema),
    defaultValues: {
      full_name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      phone: data.phone,
    },
  });

  const onSubmit = (data: EditStudentInput) => {
    console.log(data);
  };

  return (
    <div className='bg-white absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 w-[90%] lg:w-[600px] min-h-[300px] shadow-[24px] p-4 rounded-lg'>
      <button
        onClick={closeModal}
        className='shadow-all-sides absolute -top-4 -right-4 z-50 bg-white p-2 rounded-full text-gray-500 hover:text-gray-700 cursor-pointer'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-5 w-5'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>

      <div>
        <h3 className='text-lg font-semibold'>Edit Student Details</h3>
        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formControl}>
            <label htmlFor='full_name' className={styles.label}>
              Full Name
            </label>
            <input
              {...register('full_name')}
              placeholder='full_name'
              type='text'
              className={styles.input}
              data-cy='full_name'
            />
            <p className={styles.error}>
              {errors.full_name?.message as string}
            </p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='email' className={styles.label}>
              Email
            </label>
            <input
              {...register('email')}
              placeholder='email'
              type='email'
              className={styles.input}
              data-cy='email'
            />
            <p className={styles.error}>{errors.email?.message as string}</p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='phone' className={styles.label}>
              Phone
            </label>
            <input
              {...register('phone')}
              placeholder='phone'
              type='text'
              className={styles.input}
              data-cy='phone'
            />
            <p className={styles.error}>{errors.phone?.message as string}</p>
          </div>

          <div className='mt-8 flex justify-start'>
            <Button
              data-cy='submit'
              type='submit'
              variant='primary'
              //isLoading={isPending}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
