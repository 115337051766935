import React from 'react';

import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Head } from '../../../components/Head';
import { TheVisionary } from '../components/TheVisionary';
import { WhatWeDo } from '../components/WhatWeDo';
import { WhyWeDoWhatWeDo } from '../components/WhyWeDoWhatWeDo';

export const About = () => {
  return (
    <>
      <Head title='About' />

      <div className='flex flex-col min-h-[50vh] px-8'>
        <Breadcrumbs page='About Us' />

        <WhatWeDo />
        <WhyWeDoWhatWeDo />
        <TheVisionary />
      </div>
    </>
  );
};
