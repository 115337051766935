import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Button, Link, PasswordInput } from '../../../components/Elements';
import { Head } from '../../../components/Head';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import styles from '../../../utils/styles';
import { useGoogleLogin } from '../api/login';
import { useRegister } from '../api/register';
import { GoogleSignInBtn } from '../components/GoogleSignInBtn';
import Header from '../components/Header';
import { UserRegistrationInput, userRegistrationSchema } from '../types';

export const Register = () => {
  const { isPending, mutate: registerUser, isError, error } = useRegister();
  const {
    mutate: googleLogin,
    isError: isGoogleError,
    error: googleError,
  } = useGoogleLogin();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UserRegistrationInput>({
    resolver: zodResolver(userRegistrationSchema),
  });

  const onSubmit = (data: UserRegistrationInput) => {
    registerUser(data);
  };

  return (
    <>
      <Head title='Sign Up' />
      <div className='flex flex-col px-8 max-w-lg mx-auto py-8 sm:py-16'>
        <Header title='Welcome' subTitle='Enter your details to sign up' />
        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          {isError ? (
            <div className=' text-sm font-semibold text-red-500 mb-2 text-center'>{`${getErrorMsg(
              error as unknown as ErrorProps,
            )}`}</div>
          ) : null}
          {isGoogleError ? (
            <div className='text-sm font-semibold text-red-500 mb-2 text-center'>{`${getErrorMsg(
              googleError as unknown as ErrorProps,
            )}`}</div>
          ) : null}
          <div className={`${styles.formControl} flex flex-col sm:flex-row`}>
            <div className='sm:mr-4 flex-[0.5] mb-4 sm:mb-0'>
              <label htmlFor='first_name' className={styles.label}>
                First Name
              </label>
              <input
                {...register('first_name')}
                placeholder='Enter your first name'
                type='text'
                className={styles.input}
                data-cy='first_name'
              />
              <p className={styles.error}>
                {errors.first_name?.message as string}
              </p>
            </div>

            <div className='flex-[0.5]'>
              <label htmlFor='last_name' className={styles.label}>
                Last Name
              </label>
              <input
                {...register('last_name')}
                placeholder='Enter your last name'
                type='text'
                className={styles.input}
                data-cy='last_name'
              />
              <p className={styles.error}>
                {errors.last_name?.message as string}
              </p>
            </div>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='email' className={styles.label}>
              Email
            </label>
            <input
              {...register('email')}
              placeholder='Enter your email'
              type='email'
              className={styles.input}
              data-cy='email'
            />
            <p className={styles.error}>{errors.email?.message as string}</p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='password' className={styles.label}>
              Password
            </label>
            <PasswordInput
              placeholder='Enter your password'
              registration={register('password')}
            />
            <p className={styles.error}>{errors.password?.message as string}</p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='confirm_password' className={styles.label}>
              Re-enter Password
            </label>
            <PasswordInput
              placeholder='Re-enter your password'
              registration={register('confirm_password')}
            />
            <p className={styles.error}>
              {errors.confirm_password?.message as string}
            </p>
          </div>

          <div className='mt-8 flex justify-start'>
            <Button
              data-cy='submit'
              type='submit'
              variant='primary'
              isLoading={isPending}
            >
              Sign Up
            </Button>
          </div>
        </form>
        <div className='text-center my-2'>or</div>

        <GoogleSignInBtn googleLogin={googleLogin} />

        <div className='text-center mt-4 text-sm'>
          <span>Already have an account?</span>{' '}
          <Link
            to='/auth/login'
            className='text-secondary font-semibold hover:text-primary'
          >
            Sign in
          </Link>
        </div>
      </div>
    </>
  );
};
