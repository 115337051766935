import React from 'react';

import { Head } from '../../../components/Head';

export const NotFound = () => {
  return (
    <>
      <Head title='Page Not Found' />
      <div className='flex flex-col items-center justify-center h-[50vh] text-4xl font-bold'>
        Page Does Not Exist
      </div>
    </>
  );
};
