import {
  bookIcon,
  coursesIcon,
  dashboardIcon2,
  homeIcon,
  recordsIcon,
  settingsIcon,
  studentsIcon,
} from '../assets';

type LinkProp = { pathname: string; title: string; icon?: any };

export const websiteLinks: LinkProp[] = [
  {
    pathname: 'about-us',
    title: 'About Us',
  },
  {
    pathname: 'courses',
    title: 'Courses',
  },
  {
    pathname: 'contact',
    title: 'Contact',
  },
];

export const studentPortalLinks: LinkProp[] = [
  {
    pathname: '/student',
    title: 'Home',
    icon: homeIcon,
  },
  {
    pathname: '/student/my-courses',
    title: 'My Courses',
    icon: bookIcon,
  },
  {
    pathname: '/student/settings',
    title: 'Settings',
    icon: settingsIcon,
  },
];

export const adminPortalLinks: LinkProp[] = [
  {
    pathname: '/admin',
    title: 'Dashboard',
    icon: dashboardIcon2,
  },
  {
    pathname: '/admin/students',
    title: 'Students',
    icon: studentsIcon,
  },
  {
    pathname: '/admin/courses',
    title: 'Courses',
    icon: coursesIcon,
  },
  {
    pathname: '/admin/live-sessions',
    title: 'Live Sessions',
    icon: recordsIcon,
  },
  {
    pathname: '/admin/staff',
    title: 'Staff',
    icon: recordsIcon,
  },
];
