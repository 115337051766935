import { Box, Typography } from '@mui/material';

import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Head } from '../../../components/Head';
import { Form } from '../components/Form';

export const Contact = () => {
  return (
    <>
      <Head title='Contact' />

      <div className='flex flex-col px-8 '>
        <Breadcrumbs page='Contact' />

        <div className='flex flex-col mt-8 sm:max-w-[600px] 2xl:mx-auto'>
          <Box>
            <Typography>
              <Typography
                data-cy='text-secondary'
                fontSize={'clamp(24px, 2vw, 48px)'}
                fontWeight={'bolder'}
                marginBottom='10px'
                className='text-secondary'
              >
                Leave Us a Message
              </Typography>
              <Typography
                //pr={{ lg: '35%', xl: '35%', md: '35%', sm: '0%' }}
                color={'#685E6F'}
                // width={'60%'}
              >
                The best and quickest way to reach us is via the contact form
                below. Please complete and submit form and we will get back to
                you as soon as possible.
              </Typography>
            </Typography>
          </Box>
          <Form />
        </div>
      </div>
    </>
  );
};
