import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Button, Spinner } from '../../../components/Elements';
import { Head } from '../../../components/Head';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import { notifyError, notifySuccess } from '../../../utils/notifications';
import { useResendEmail, useVerifyEmail } from '../api/register';
import Header from '../components/Header';

export const VerifyEmail = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { token } = params;

  const { search } = useLocation();
  const email = search.split('?email=')[1];

  useEffect(() => {
    if (token) {
      verifyEmail(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const {
    mutate: verifyEmail,
    isPending,
    isSuccess,
    isError,
    error,
    reset,
  } = useVerifyEmail();

  const {
    mutate: resendEmail,
    isPending: isResendEmailPending,
    isSuccess: isResendEmailSuccess,
    isError: isResendEmailError,
    error: resendEmailError,
    reset: resetResendEmail,
  } = useResendEmail();

  if (isSuccess) {
    const timeout = 2000;

    notifySuccess('Email Verficication Successful', 1000);
    reset();

    setTimeout(() => {
      navigate('/auth/login');
    }, timeout);
  }

  if (isResendEmailSuccess) {
    resetResendEmail();
    reset();
    navigate('/auth/registration-success', {
      state: { email },
    });
  }

  if (isResendEmailError) {
    notifyError(getErrorMsg(resendEmailError as unknown as ErrorProps), false);
    resetResendEmail();
  }

  return (
    <>
      <Head title='Email Verification' />
      <div className='flex flex-col h-screen px-8 max-w-lg mx-auto py-8 sm:py-16'>
        <Header
          title='Email Verification'
          subTitle={isPending ? 'Please wait while we verify your email' : ''}
        />

        <div className='flex mt-16 justify-center flex-1'>
          {isPending && <Spinner size='lg' />}

          {isError && (
            <div className='flex flex-col items-center'>
              <span className='text-red-500 text-lg font-medium -mt-12'>
                {getErrorMsg(error as unknown as ErrorProps)}
              </span>

              <Button
                type='button'
                variant='secondary'
                className='mt-8'
                onClick={() => resendEmail(email as string)}
                isLoading={isResendEmailPending}
              >
                Resend Verification Email
              </Button>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
