import { facebookIcon, instagramIcon, logo, mail, phone } from '../../assets';

export const Footer = () => {
  return (
    <div className='min-h-[260px] flex flex-col'>
      <div className='min-h-[100px] px-2 md:px-8 bg-[#FBFBFB] flex-col flex md:flex-row flex-1'>
        <div className='logo-bg flex-[0.5] py-2 px-8 md:px-0'>
          <img alt='logo-black' src={logo} className='w-[128px]' />
          <p className='mt-4 text-sm md:mr-8 lg:mr-16 text-secondary'>
            Learners choose AwesumEdge to build relevant and in-demand tech
            skills to unlock their potential.
          </p>
        </div>

        <div className='items-center justify-center flex-[0.5] px-8 align-middle py-2'>
          <div className='flex flex-col flex-wrap justify-between w-full mt-4 sm:flex-row md:mt-0'>
            <div className='mb-2 flex-[0.4] mr-8'>
              <h3 className='mb-1 font-semibold text-secondary'>Quick Links</h3>

              <div className='flex justify-between'>
                <ul>
                  <li className='text-secondary hover:text-primary text-[12px] my-4'>
                    <a href='/about-us'>About</a>
                  </li>
                  <li className='text-secondary hover:text-primary text-[12px] my-4'>
                    <a href='/courses'>Courses</a>
                  </li>
                </ul>

                <ul>
                  <li className='text-secondary hover:text-primary text-[12px] my-4'>
                    <a
                      target='blank'
                      href='https://www.youtube.com/@tuplespectra'
                    >
                      Blog
                    </a>
                  </li>
                  <li className='text-secondary hover:text-primary text-[12px] my-4'>
                    <a href='/contact'>Contact</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className='mb-2 flex-[0.6]'>
              <h3 className='mb-1 font-semibold text-secondary'>Contact Us</h3>

              <ul>
                <li className='text-secondary hover:text-primary text-[12px] my-4 flex'>
                  <img
                    src={phone}
                    alt='phone icon'
                    className='w-[16px] h-[16px] object-contain mr-4'
                  />

                  <a href='tel:832-614-1954'>832-614-1954</a>
                </li>

                <li className='text-secondary hover:text-primary text-[12px] my-4 flex'>
                  <img
                    src={mail}
                    alt='mail icon'
                    className='w-[16px] h-[16px] object-contain  mr-4'
                  />

                  <a href='mailto: info@awesumedge.com'>info@awesumedge.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-gradient min-h-[60px] px-8 flex flex-col py-2 sm:py-0 sm:flex-row flex-1 justify-between items-center'>
        <span className='text-sm text-white'>
          Copyright {new Date().getFullYear()} | All Rights Reserved{' '}
        </span>
        <div className='flex mt-2 sm:mt-0'>
          <a href='#' className='rounded'>
            <img
              src={facebookIcon}
              alt='Facebook Icon'
              className='w-6 h-6 mr-4'
            />
          </a>
          <a href='#'>
            <img
              src={instagramIcon}
              alt='Instagram Icon'
              className='w-6 h-6 mr-4'
            />
          </a>
        </div>
      </div>
    </div>
  );
};
