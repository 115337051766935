import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { TypeOf, object, string } from 'zod';

import { Button, Link } from '../../../components/Elements';
import { Head } from '../../../components/Head';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import { notifyError, notifySuccess } from '../../../utils/notifications';
import styles from '../../../utils/styles';
import { useRequestPasswordReset } from '../api/requestPasswordReset';
import Header from '../components/Header';

const requestPasswordResetSchema = object({
  email: string().email().min(1, 'Email is Required'),
});

export type RequestPasswordResetInput = TypeOf<
  typeof requestPasswordResetSchema
>;

export const RequestPasswordReset = () => {
  const {
    mutate: requestPasswordReset,
    isPending,
    isSuccess,
    isError,
    data,
    error,
    reset,
  } = useRequestPasswordReset();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: resetForm,
  } = useForm<RequestPasswordResetInput>({
    resolver: zodResolver(requestPasswordResetSchema),
  });

  if (isSuccess) {
    notifySuccess(data.data?.message, false);
    reset();
    resetForm();
  }

  if (isError) {
    notifyError(getErrorMsg(error as unknown as ErrorProps), false);
    reset();
  }

  const onSubmit = (data: RequestPasswordResetInput) => {
    requestPasswordReset(data);
  };

  return (
    <>
      <Head title='Password Reset' />
      <div className='flex flex-col px-8 max-w-lg mx-auto py-8 sm:py-16'>
        <Header
          title='Reset Password'
          subTitle='Enter your email and we will send you instructions to reset your password'
        />
        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formControl}>
            <label htmlFor='email' className={styles.label}>
              Email
            </label>
            <input
              data-cy='email'
              {...register('email')}
              placeholder='email'
              type='email'
              className={styles.input}
            />
            <p className={styles.error}>{errors.email?.message as string}</p>
          </div>
          <div className='mt-8 flex justify-start'>
            <Button
              data-cy='submit'
              type='submit'
              variant='primary'
              isLoading={isPending}
            >
              Reset Password
            </Button>
          </div>
        </form>
      </div>

      <div className='text-center mt-4 text-sm'>
        <span>Return to</span>{' '}
        <Link
          to='/auth/login'
          className='text-secondary font-semibold hover:text-primary'
        >
          Sign in
        </Link>
      </div>
      <ToastContainer />
    </>
  );
};
