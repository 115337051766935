export const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const categories = [
  {
    id: '7896ae4f-12a2-48b6-9e4b-80746fab7748',
    name: 'Pre-recorded',
    description: 'this is a prerecorded one',
  },
  {
    id: '6896ae4f-12a2-48b6-9e4b-80746fab7748',
    name: 'Live Session',
    description: 'live workshops',
  },
];
