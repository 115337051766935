type LoaderProps = {
  isLoading: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <div className='loader-overlay flex flex-col'>
      <div className='loader-spinner'></div>
      <p className='text-sm text-slate-100'>Please wait...</p>
    </div>
  ) : null;
};
