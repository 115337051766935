import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import storage from '../../../../utils/storage';

const changePassword = async (payload: {
  current_password: string;
  new_password: string;
}) => {
  const resp = await axios.post('/user/update-password/', payload);
  return resp.data;
};

const changeDetails = async (payload: {
  first_name?: string;
  last_name?: string;
  image?: string;
  country?: string;
}) => {
  const resp = await axios.patch('/user/me', payload);
  return resp.data;
};

export const useChangePassword = () => {
  return useMutation({
    mutationFn: changePassword,
  });
};

export const useChangeDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: changeDetails,
    onSuccess: (data) => {
      storage.setUser(data?.data);
      queryClient.setQueryData([`user`], (oldQueryData: any) => {
        console.log({ oldQueryData, data });

        return {
          ...oldQueryData,
          data: {
            ...oldQueryData.data,
            ...data.data,
          },
        };
      });
    },
  });
};
