import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_URL } from '../../../../config';
import { axios } from '../../../../lib/axios';
import storage from '../../../../utils/storage';
import { getUpcomingLiveSessions } from '../../../liveworkshops/api';
import { ScheduleLiveSessionInput } from '../types';

const uploadImage = async (formData: FormData) => {
  const token = storage.getToken();

  const resp = await fetch(`${API_URL}/sessions/upload-image/`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  const respJSON = await resp.json();

  return respJSON;
};

const scheduleLiveSession = async (payload: ScheduleLiveSessionInput) => {
  const resp = await axios.post('/sessions/live/', payload);

  return resp.data;
};

export const useUploadImage = () => {
  return useMutation({
    mutationFn: uploadImage,
  });
};

export const useScheduleLiveSession = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: scheduleLiveSession,
    onSuccess: async (data) => {
      try {
        const oldQueryData: any = queryClient.getQueryData([
          'upcoming-live-sessions',
        ]);

        let update;

        if (!oldQueryData) {
          const apiResp = await getUpcomingLiveSessions();

          if (apiResp.status === 200) {
            update = {
              ...apiResp,
              data: [...apiResp.results.data, data.data],
            };
          }
        } else {
          update = {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              count: oldQueryData.data.count + 1,
              results: [...oldQueryData.data.results, data.data],
            },
          };
        }

        queryClient.setQueryData(['upcoming-live-sessions'], update);
      } catch (error) {
        console.log(error);
      }
    },
  });
};
