import { User } from '../features/auth';

const storagePrefix = 'awesumedge_';

const storage = {
  getToken: () => {
    return window.sessionStorage.getItem(`${storagePrefix}token`)
      ? JSON.parse(
          window.sessionStorage.getItem(`${storagePrefix}token`) as string,
        )
      : null;
  },
  getUser: () => {
    return window.sessionStorage.getItem(`${storagePrefix}user`)
      ? JSON.parse(
          window.sessionStorage.getItem(`${storagePrefix}user`) as string,
        )
      : null;
  },
  setToken: (token: string) => {
    window.sessionStorage.setItem(
      `${storagePrefix}token`,
      JSON.stringify(token),
    );
  },
  setUser: (user: User) => {
    window.sessionStorage.setItem(`${storagePrefix}user`, JSON.stringify(user));
  },
  clearToken: () => {
    console.log('clear token');

    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },
  clearUser: () => {
    window.sessionStorage.removeItem(`${storagePrefix}user`);
  },
};

export default storage;
