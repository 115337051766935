export const ENV = process.env.NODE_ENV;
export const API_URL = process.env.REACT_APP_API_URL as string;
export const WEB_URL =
  ENV === 'development'
    ? `http://localhost:${process.env.REACT_APP_PORT}`
    : (process.env.REACT_APP_WEB_URL as string);
export const VERIFICATION_REDIRECT_URL = (WEB_URL +
  '/auth/verify-email') as string;
export const PASSWORD_RESET_REDIRECT_URL = (WEB_URL +
  '/auth/set-new-password') as string;
//export const USD_IN_NAIRA = Number(process.env.REACT_APP_USD_IN_NAIRA);
export const GOOGLE_CLIENT_ID = process.env
  .REACT_APP_GOOGLE_CLIENT_ID as string;
export const APP_PORT = process.env.REACT_APP_PORT as string;
