import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Button, Link, PasswordInput } from '../../../components/Elements';
import { Head } from '../../../components/Head';
import { ErrorProps } from '../../../types';
import getErrorMsg from '../../../utils/getErrorMsg';
import styles from '../../../utils/styles';
import { useGoogleLogin, useLogin } from '../api/login';
import { GoogleSignInBtn } from '../components/GoogleSignInBtn';
import Header from '../components/Header';
import { LoginInput, loginSchema } from '../types';

export const Login = () => {
  const { isPending, mutate: login, isError, error } = useLogin();

  const {
    mutate: googleLogin,
    isError: isGoogleError,
    error: googleError,
  } = useGoogleLogin();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = (data: LoginInput) => {
    login(data);
  };

  return (
    <>
      <Head title='Login' />

      <div className='flex flex-col px-8 max-w-lg mx-auto py-8 sm:py-16'>
        <Header title='Welcome Back' subTitle='Enter your details to log in' />
        <form className='max-w-[600px] mt-4' onSubmit={handleSubmit(onSubmit)}>
          {isError ? (
            <div className='text-sm font-semibold text-red-500 mb-2 text-center'>{`${getErrorMsg(
              error as unknown as ErrorProps,
            )}`}</div>
          ) : null}
          {isGoogleError ? (
            <div className='text-sm font-semibold text-red-500 mb-2 text-center'>{`${getErrorMsg(
              googleError as unknown as ErrorProps,
            )}`}</div>
          ) : null}
          <div className={styles.formControl}>
            <label htmlFor='email' className={styles.label}>
              Email
            </label>
            <input
              data-cy='email'
              {...register('email')}
              placeholder='Enter your email'
              type='email'
              className={styles.input}
            />
            <p className={styles.error}>{errors.email?.message as string}</p>
          </div>

          <div className={styles.formControl}>
            <label htmlFor='password' className={styles.label}>
              Password
            </label>
            <PasswordInput
              placeholder='Enter your password'
              registration={register('password')}
            />
            <p className={styles.error}>{errors.password?.message as string}</p>
          </div>

          <div className='flex justify-end'>
            <Link
              data-cy='request-password-reset'
              to='/auth/reset-password'
              className='text-secondary text-[14px] -mt-2 hover:text-primary'
            >
              Forgot Password
            </Link>
          </div>

          <div className='mt-8 flex justify-start'>
            <Button
              data-cy='submit'
              type='submit'
              variant='primary'
              isLoading={isPending}
            >
              Sign In
            </Button>
          </div>
        </form>

        <div className='text-center my-2'>or</div>

        <GoogleSignInBtn googleLogin={googleLogin} />
        <div className='text-center mt-4 text-sm'>
          <span>Don&lsquo;t have an account?</span>{' '}
          <Link
            data-cy='signup'
            to='/auth/register'
            className='text-secondary font-semibold hover:text-primary'
          >
            Create Account
          </Link>
        </div>
      </div>
    </>
  );
};
