import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';
import { getMediaType } from '../../../../utils/misc';
import { LiveSessionDetailsResp, MediaType } from '../types';

import { getSectionDetails } from './getLiveSession';

const createNewSection = async (payload: {
  title: string;
  order: number;
  course: string;
  session_id: string;
}) => {
  const resp = await axios.post('/sessions/sections/', payload);
  return {
    ...resp.data,
    session_id: payload.session_id,
    section_id: resp.data?.id,
  };
};

const createNewResource = async (payload: {
  file: File;
  title: string;
  order: number;
  section_id: string;
  session_id: string;
}) => {
  try {
    const file_name = payload.file.name.split('.')[0];
    const file_type = getMediaType(payload.file);

    // Check if media type is supported
    if (!file_type) {
      throw new Error('Unsupported media type');
    }

    // 1. Get presigned URL
    const s3URLResp = await generateS3PreSignedURL({
      file_name,
      file_type,
      section_id: payload.section_id,
    });

    if (!s3URLResp.data.presigned_url) {
      throw new Error('Failed to generate S3 presigned URL');
    }

    // 2. Upload resource directly to S3
    const s3UploadResp = await uploadToS3(
      s3URLResp.data.presigned_url,
      payload.file,
      file_type,
    );

    if (s3UploadResp.status !== 200) {
      throw new Error('Failed to upload file to S3');
    }

    // 3. Proceed to create resource
    const resp = await axios.post('/sessions/update-resource/', {
      file_name,
      file_key: s3URLResp.data.file_key,
      file_type,
      title: payload.title,
      order: String(payload.order),
      section_id: payload.section_id,
    });

    if (!resp.data.data) {
      throw new Error('Failed to create resource');
    }

    return {
      ...resp.data.data,
      section_id: payload.section_id,
      title: payload.title,
      file_type,
      session_id: payload.session_id,
    };
  } catch (error) {
    console.error('Error creating new resource:', error);
    throw error;
  }
};

const deleteSection = async (payload: {
  sectionID: string;
  sessionID: string;
}) => {
  const resp = await axios.delete(`/sessions/sections/${payload.sectionID}`);

  if (resp.status === 204) {
    return {
      id: payload.sectionID,
      session_id: payload.sessionID,
    };
  }

  return resp.data;
};

const deleteResource = async (payload: {
  resourceID: string;
  sectionID: string;
  sessionID: string;
  successCB: any;
}) => {
  const resp = await axios.delete('/sessions/upload-material/', {
    data: {
      resource_id: payload.resourceID,
    },
  });

  if (resp.status === 204) {
    return {
      id: payload.resourceID,
      section_id: payload.sectionID,
      session_id: payload.sessionID,
      successCB: payload.successCB,
    };
  }

  return resp.data;
};

const generateS3PreSignedURL = async (payload: {
  file_name: string;
  file_type: MediaType;
  section_id: string;
}) => {
  console.log({ payload });

  const formData = new FormData();

  formData.append('file_name', payload.file_name);
  formData.append('file_type', payload.file_type);
  formData.append('section_id', payload.section_id);

  const resp = await axios.post('/sessions/upload-material/', formData);

  return resp.data;
};

const uploadToS3 = async (s3URL: string, file: File, file_type: MediaType) => {
  const fileData = await file.arrayBuffer(); // convert file to binary

  const resp = await fetch(s3URL, {
    method: 'PUT',
    headers: {
      'Content-Type': file_type,
    },
    body: fileData,
  });

  return resp;
};

const updateModuleTitle = async (payload: {
  moduleID: string;
  title: string;
}) => {
  const data = new URLSearchParams();
  data.append('title', payload.title);
  const resp = await axios.patch(
    `/sessions/sections/${payload.moduleID}`,
    data,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  );

  return resp.data;
};

export const useCreateNewSection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createNewSection,
    onSuccess: (data) => {
      queryClient.setQueryData(
        [`live-session_${data.session_id}`],
        (oldQueryData: LiveSessionDetailsResp) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              sections: [
                ...oldQueryData.data.sections,
                { ...data, section_id: data.id },
              ],
            },
          };
        },
      );
    },
  });
};

export const useCreateNewResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createNewResource,
    onSuccess: async (data) => {
      const updatedSection = await getSectionDetails(data.section_id);

      queryClient.setQueryData(
        [`live-session_${data.session_id}`],
        (oldQueryData: LiveSessionDetailsResp) => {
          const updatedItemIndex = oldQueryData.data.sections.findIndex(
            (item: any) => item.id === data.section_id,
          );

          if (updatedItemIndex !== -1) {
            const updatedSections = [...oldQueryData.data.sections];

            updatedSections[updatedItemIndex] = updatedSection;

            const updatedResp = {
              ...oldQueryData,
              data: {
                ...oldQueryData.data,
                sections: updatedSections,
              },
            };

            return updatedResp;
          }

          // If the section with the specified ID is not found, return the original data.
          return oldQueryData;
        },
      );
    },
  });
};

export const useDeleteSection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteSection,
    onSuccess: (data) => {
      queryClient.setQueryData(
        [`live-session_${data.session_id}`],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              sections: oldQueryData.data.sections.filter(
                (section: any) => section.id !== data.id,
              ),
            },
          };
        },
      );
    },
  });
};

export const useDeleteResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteResource,
    onSuccess: (data) => {
      queryClient.setQueryData(
        [`live-session_${data.session_id}`],
        (oldQueryData: any) => {
          oldQueryData.data.sections.forEach((section: any) => {
            if (section.id === data.section_id) {
              section.resources = section.resources.filter(
                (resource: any) => resource.id !== data.id,
              );
            }
          });
        },
      );
    },
  });
};

export const useUpdateModuleTitle = () => {
  return useMutation({
    mutationFn: updateModuleTitle,
  });
};
