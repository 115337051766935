import { Typography } from '@mui/material';
import React from 'react';

export const WhyWeDoWhatWeDo = () => {
  return (
    <div
      className='mb-8'
      id='why-we-do-what-we-do'
      style={
        {
          // marginTop: '2rem',
          //textAlign: 'center',
          // maxWidth: '900px',
          // margin: 'auto',
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
        }
      }
    >
      <Typography
        fontSize={'clamp(24px, 2vw, 48px)'}
        //paddingTop={{ lg: '70px', md: '20px' }}
        fontWeight={'bolder'}
        marginBottom='10px'
        color={'rgba(13, 6, 95, 1)'}
      >
        Why We Do What We Do
      </Typography>
      <Typography
        // paddingLeft={{ lg: '10vw', md: '10vw', sm: '5vw', xs: '3vw' }}
        // paddingRight={{ lg: '10vw', md: '10vw', sm: '5vw', xs: '3vw' }}
        color={'rgba(79, 79, 79, 1)'}
      >
        To build world leaders through impacting knowledge. To provide learners
        with low-cost high-quality education which can be accessed anywhere and
        anytime to better improve their skills thus becoming a success.
      </Typography>
    </div>
  );
};
