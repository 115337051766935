import { PencilIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '../../../../components/Elements';
import { LiveSessionProps } from '../../../liveworkshops/types';
import { useCompleteLiveSession } from '../api/completeLiveSession';
import { useGetOngoingLiveSessions } from '../api/getLiveSession';

const OngoingSession: React.FC<{
  data: LiveSessionProps;
  hasBottomMargin: boolean;
}> = ({ data, hasBottomMargin }) => {
  const navigate = useNavigate();
  const { mutate: completeLiveSession, isPending } = useCompleteLiveSession();

  const [selectedLiveSession, setSelectedLiveSession] = useState('');
  return (
    <div
      className={`flex flex-1 flex-col sm:flex-row sm:h-[150px] border-[#D9D9D9] border-[1px] ${hasBottomMargin ? 'mb-4' : ''}`}
    >
      <div className='flex sm:flex-[0.3]'>
        <img
          src={data.image?.presigned_url}
          alt={data.title}
          className='w-full h-full object-cover'
        />
      </div>

      <div className='flex flex-col sm:flex-row sm:flex-[0.7] ml-4 m-4'>
        <div className='flex flex-col flex-[0.6]  justify-between'>
          <h3 className='text-xl font-bold text-secondary mb-4'>
            {data.title}
          </h3>

          <div className='flex lg:justify-between text-sm'>
            <div>
              <span>Duration: </span>
              <span className='font-bold'>
                {parseInt(data.duration_weeks)} Weeks
              </span>
            </div>

            <div className='ml-2'>
              <span>Current Week: </span>
              <span className='font-bold'>
                {data.current_week ? data.current_week : 0}
              </span>
            </div>
          </div>

          <div>
            <span className='text-xs'>{data.no_of_students} students</span>
          </div>
        </div>
        <div className='flex flex-row justify-between sm:flex-col flex-[0.4] '>
          <button
            onClick={() => {
              navigate(`/admin/live-sessions/edit/${data.id}`);
            }}
            className='flex items-center w-[48px] bg-white self-end hover:text-primary'
          >
            Edit
            <PencilIcon className='h-4 w-4 ml-2' />
          </button>

          <div className='h-full flex flex-col items-end justify-end'>
            <button
              onClick={() => {
                navigate(`/admin/live-sessions/view/${data.id}`);
              }}
              className='w-[180px] py-2 rounded border-[1px] border-secondary hover:border-primary hover:text-primary font-semibold'
            >
              View Details
            </button>

            <button
              onClick={() => {
                setSelectedLiveSession(data.id);
                completeLiveSession(data.id);
              }}
              type='button'
              disabled={isPending}
              className='flex items-center justify-center w-[180px] py-2 rounded border-[1px] border-secondary hover:border-primary hover:text-primary font-semibold mt-[2px]'
            >
              {isPending && selectedLiveSession === data.id && (
                <Spinner className='mr-2' size='sm' />
              )}
              Complete Workshop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OngoingSessions = () => {
  const { data, isLoading } = useGetOngoingLiveSessions();

  return (
    <div className=' pt-4 sm:pt-8'>
      <h1 className='text-xl text-secondary font-bold'>
        Ongoing Live Sessions
      </h1>
      {isLoading && (
        <div className='min-h-[30vh] flex flex-col  items-center justify-center'>
          <Spinner size='md' />
        </div>
      )}
      {data && (
        <div className='my-4'>
          {data.length ? (
            data.map((ongoingSession: LiveSessionProps, index: number) => {
              return (
                <OngoingSession
                  data={ongoingSession}
                  key={data.id}
                  hasBottomMargin={index < data.length - 1}
                />
              );
            })
          ) : (
            <h3 className='text-slate-500 font-semibold'>No data to display</h3>
          )}
        </div>
      )}
    </div>
  );
};
