export const Filter = () => {
  return (
    <div className='bg-white absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 w-[400px] shadow-[24px] p-4 rounded-lg'>
      <h3 className='text-center'>Filter Students</h3>
      <h4 className='text-center text-slate-500 text-sm'>
        Specify a filter value
      </h4>
    </div>
  );
};
