import React from 'react';

import { getDiscountedPrice } from '../../../utils/converter';
import { LiveSessionProps } from '../types';

export const UpcomingSessionPrice: React.FC<{ data: LiveSessionProps }> = ({
  data,
}) => {
  return (
    <span className='font-bold'>
      {parseInt(data.discount_percentage) === 100 ? (
        'FREE'
      ) : (
        <>
          ${getDiscountedPrice(data.discount_percentage, data.price_usd)} | ₦
          {getDiscountedPrice(
            data.discount_percentage,
            data.price_ngn,
          )!.toLocaleString()}
        </>
      )}
    </span>
  );
};
