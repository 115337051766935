import { DateTime } from 'luxon';

//import { USD_IN_NAIRA } from '../config';

export const formatDate = (date: Date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const formattedDate = `${day} ${months[monthIndex]}, ${year}`;
  return formattedDate;
};

const removeSeconds = (time: string) => {
  const parts = time.split(':');
  const formattedTime = parts[0] + ':' + parts[1];

  return formattedTime;
};

export const convertTime = (time: string) => {
  try {
    const formattedTime = removeSeconds(time);
    const utcTime = DateTime.fromFormat(formattedTime, 'HH:mm', {
      zone: 'UTC',
    });

    const cstTime = utcTime.setZone('America/Chicago');
    const watTime = utcTime.setZone('Africa/Lagos');

    return {
      cstTime: cstTime.toFormat('h:mma'),
      watTime: watTime.toFormat('h:mma'),
    };
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
};

// export const convertToNGN = (usd: string) => {
//   const amountInNaira = parseInt(usd) * USD_IN_NAIRA;
//   return amountInNaira.toLocaleString(); //String(amountInNaira / 1000) + 'k';
// };

export const convertToAbbreviation = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase().slice(0, 2);
};

export const convertToTitleCase = (text: string) => {
  if (!text) {
    return '';
  }
  const arr: string[] = text.split('');
  const first = arr.shift() as string;
  return first.toUpperCase() + arr.join('');
};

export const formatTitle = (text: string) => {
  if (text.includes('-')) {
    const arr = text.split('-');
    return `${convertToTitleCase(arr[0])} ${convertToTitleCase(arr[1])} ${convertToTitleCase(arr[2])}`;
  }
  return convertToTitleCase(text);
};

export const removeEmptyProperties = (obj: { [key: string]: any }) => {
  // Helper function to check if a value is empty
  const isEmpty = (value: any) =>
    value === '' || value === null || value === undefined;

  // Iterate over the object entries
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        // If the value is an object, recursively remove empty properties
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          value = removeEmptyProperties(value);
        }

        // If the value is an array, handle the specific case for `questions`
        if (Array.isArray(value)) {
          if (key === 'questions') {
            value = value.filter(
              (question) => !isEmpty(question.question_text),
            );
          } else {
            value = value.filter((item) => !isEmpty(item));
          }
        }

        return [key, value];
      })
      .filter(([_, value]) => !isEmpty(value)), // Remove entries with empty values
  );
};

// export const removeEmptyProperties = (obj: { [x: string]: any }) => {
//   return Object.fromEntries(
//     Object.entries(obj).filter(([_, value]) => {
//       return value !== '' && value !== null && value !== undefined;
//     }),
//   );
// };

export const getDiscountedPrice = (
  discount_percentage: string,
  price: string,
) => {
  if (!Number(discount_percentage)) {
    return parseInt(price);
  }
  return (
    parseInt(price) - (parseInt(price) * parseInt(discount_percentage)) / 100
  );
};
