/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import { documentIcon, videoIcon } from '../../../../assets';
import { ResourceDetails } from '../../types';

export const Media: React.FC<{
  openResourceDetails: ResourceDetails;
}> = ({ openResourceDetails }) => {
  if (!openResourceDetails) {
    return <p>No content has been uploaded for this module yet.</p>;
  }

  const disableContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div className='h-[200px] sm:h-[380px] 2xl:h-[600px] relative flex w-full'>
      {openResourceDetails.file_type === 'video/mp4' ? (
        <div className='absolute inset-0 flex justify-center bg-grey rounded-lg'>
          <video
            className='w-full h-full rounded-lg'
            src={openResourceDetails.presigned_url}
            title={openResourceDetails.title}
            controls
            onContextMenu={disableContextMenu}
          ></video>
        </div>
      ) : (
        <div>
          <h2 className='text-3xl'>Resources for this lecture</h2>
          <div className='flex mt-2 items-center'>
            <img
              className='h-4 w-4 mr-2'
              src={
                openResourceDetails.file_type === 'video/mp4'
                  ? videoIcon
                  : documentIcon
              }
              alt='icon'
            />{' '}
            <a
              href={openResourceDetails.presigned_url}
              target='blank'
              className='link text-blue-600'
            >
              {openResourceDetails.title}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
