import { useNavigate } from 'react-router-dom';

import { workshop1Banner } from '../../../../assets';
import { Spinner } from '../../../../components/Elements';
import { Head } from '../../../../components/Head';
import { getBarColor, getProgressPercentage } from '../../../../utils/misc';
import { UpcomingSessions } from '../../../liveworkshops/components/UpcomingSessions';
import { useGetStudentCourses } from '../../mycourses/api/courses';
import { CourseDetailsProps, CourseProgressProps } from '../../types';

const summary = [
  {
    id: 'total_courses_enrolled',
    title: 'Total Courses Enrolled',
    value: '12',
  },
  {
    id: 'courses_in_progress',
    title: 'Courses in Progress',
    value: '3',
  },
  {
    id: 'completed_courses',
    title: 'Completed Courses',
    value: '4',
  },
  // {
  //   id: 'total_content_watched',
  //   title: 'Total Content Watched',
  //   value: '13h 4m',
  // },
];

export const Dashboard = () => {
  const { data, isLoading } = useGetStudentCourses();
  const navigate = useNavigate();

  return isLoading ? (
    <div className='min-h-[50vh] flex flex-col  items-center justify-center'>
      <Spinner size='md' />
    </div>
  ) : (
    <>
      <Head title='Student Dashboard' />
      <div className='dashboard-container'>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 md:gap-y-0 md:gap-x-8'>
          {summary.map((item) => (
            <div
              className='min-h-[90px] text-center p-2 border-[1px] border-slate-300 rounded-md flex flex-col items-center'
              key={item.title}
            >
              <h3 className='text-slate-500 text-sm md:text-md'>
                {item.title}
              </h3>

              <h1 className='text-xl md:text-2xl font-semibold mt-2'>
                {data[item.id] ?? 0}
              </h1>
            </div>
          ))}
        </div>

        {data.progress_details.length ? (
          <div className='mt-8 w-full min-h-[30vh] shadow-all-sides p-4 rounded-md'>
            <h3 className='text-lg font-semibold mb-4 '>
              Pick up where you left off
            </h3>

            {data.progress_details.map((course: CourseProgressProps) => {
              const progress = getProgressPercentage(
                course.completed_resources,
                course.total_resources,
              );

              return (
                <div
                  key={course.course_title}
                  className='flex mb-4 border-b-[1px] pb-2'
                >
                  <div className='h-16 w-16 mr-4'>
                    <img
                      className='rounded-md'
                      src={
                        data.course_details.find(
                          (courseDetails: CourseDetailsProps) =>
                            courseDetails.id === course.course_id,
                        ).image?.presigned_url ?? workshop1Banner
                        // course.img ?? workshop1Banner
                      }
                      alt='img'
                    />
                  </div>
                  <div className='flex flex-col md:flex-row w-full md:items-center'>
                    <div className='flex-1 md:flex-[0.8] flex flex-col md:mr-4'>
                      <h3>{course.course_title}</h3>
                      <div className='flex '>
                        <div className='bar flex-[0.8] mr-2 rounded-md my-4'>
                          <div
                            className={`h-full rounded-md`}
                            style={{
                              width: progress + '%',
                              backgroundColor: getBarColor(progress),
                            }}
                          ></div>
                        </div>
                        <div
                          className={`flex-[0.2] items-center self-center text-xs md:text-sm font-semibold`}
                          style={{
                            color: getBarColor(progress),
                          }}
                        >
                          {progress + '%'} complete
                        </div>
                      </div>
                    </div>

                    <div className='flex-1 md:flex-[0.2] flex md:justify-end'>
                      <button
                        onClick={() =>
                          navigate(`/student/my-courses/${course.course_id}`)
                        }
                        className='text-sm hover:bg-primary bg-secondary text-white rounded-md px-4 py-1'
                      >
                        {progress === 0 || progress === 100
                          ? 'Start'
                          : 'Resume'}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {!data.progress_details.length && data.course_details.length ? (
          <div className='mt-8 w-full min-h-[30vh] shadow-all-sides p-4 rounded-md'>
            <h3 className='text-lg font-semibold mb-4 '>
              Your registered courses
            </h3>

            {data.course_details.map((course: CourseDetailsProps) => {
              return (
                <div
                  key={course.title}
                  className='flex mb-4 border-b-[1px] pb-2'
                >
                  <div className='h-16 w-16 mr-4'>
                    <img
                      className='rounded-md'
                      src={course.image?.presigned_url}
                      alt='img'
                    />
                  </div>
                  <div className='flex flex-col md:flex-row w-full md:items-center'>
                    <div className='flex-1 md:flex-[0.8] flex flex-col md:mr-4'>
                      <h3>{course.title}</h3>
                      <div className='flex '>
                        <p className='text-xs text-slate-500'>
                          {course.status.toUpperCase()}
                        </p>
                      </div>
                    </div>

                    <div className='flex-1 md:flex-[0.2] flex md:justify-end'>
                      <button
                        onClick={() =>
                          navigate(`/student/my-courses/${course.id}`)
                        }
                        className='text-sm hover:bg-primary bg-secondary text-white rounded-md px-4 py-1'
                      >
                        Go to Course
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {!data.progress_details.length && !data.course_details.length && (
          <UpcomingSessions bgColor='#FFFFFF' />
        )}
      </div>
    </>
  );
};
