import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../lib/axios';
import storage from '../../../utils/storage';
import { getStudentCourses } from '../../student/mycourses/api/courses';
import { CourseDetailsProps } from '../../student/types';

export const getProfile = async () => {
  const response = await axios.get('/user/me');
  const { data: userData } = response;
  let userProfile;

  if (userData.data && userData.data.is_staff == false) {
    const studentCoursesResp = await getStudentCourses();
    const { data: coursesData } = studentCoursesResp;

    userProfile = {
      ...userData,
      data: {
        ...userData.data,
        courses: coursesData?.course_details?.map(
          (course: CourseDetailsProps) => course.id,
        ),
      },
    };
  }

  return userProfile ?? userData;
};

export const useGetProfile = () => {
  return useQuery({
    queryFn: getProfile,
    queryKey: ['user'],
    select: (data) => {
      storage.setUser(data?.data);
      return data?.data;
    },
  });
};
