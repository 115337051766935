import { useMutation } from '@tanstack/react-query';

import { axios } from '../../../lib/axios';
import { FormValues } from '../components';

const sendForm = async (data: FormValues) => {
  // Make a POST request to your backend API endpoint
  const response = await axios.post('/user/enquiry/', data);

  // Return the response data
  return response.data;
};
export const useSendForm = () => {
  return useMutation({
    mutationFn: sendForm,
  });
};
