import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import { workshop1Banner } from '../../../../assets';
import { Spinner } from '../../../../components/Elements';
import { Head } from '../../../../components/Head';
import {
  getBarColor,
  getCourseByProgessStatus,
  getProgressPercentage,
} from '../../../../utils/misc';
import {
  CourseDetailsProps,
  CourseProgressProps,
  StudentCoursesDataProps,
} from '../../types';
import { useGetStudentCourses } from '../api/courses';

const getTabStyle = (isActive: boolean) => {
  return `${
    isActive ? 'border-primary text-indigo' : 'text-slate-400 border-slate-200'
  } px-2 sm:px-8 text-sm sm:text-md border-b-[1px] pb-4 outline-none`;
};

const getTabContent = (
  tabKey: 'All' | 'In Progress' | 'Completed',
  studentCourses: StudentCoursesDataProps,
  goToCourse: (path: string) => void,
) => {
  const { course_details, progress_details } = studentCourses;

  return (
    <div className='grid justify-between grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 grid-template-columns: 1fr 1fr 1fr gap-4'>
      {course_details.length
        ? course_details.map((courseDetails: CourseDetailsProps) => {
            const courseID = courseDetails.id;
            const courseProgress = progress_details.find(
              (courseProgress: CourseProgressProps) =>
                courseProgress.course_id === courseID,
            );

            const progress = courseProgress
              ? getProgressPercentage(
                  courseProgress.completed_resources,
                  courseProgress.total_resources,
                )
              : 0;

            return (
              <div
                key={courseID}
                className='bg-white border-[1px] border-slate-300 rounded-xl overflow-hidden'
              >
                <img
                  src={courseDetails.image?.presigned_url ?? workshop1Banner}
                  alt={courseDetails.title}
                  className='w-full h-48 object-cover'
                />
                <div className='p-4 bg-[#F2F2F2]'>
                  <h3 className='text-lg font-semibold'>
                    {courseDetails.title}
                  </h3>

                  <div className='flex'>
                    <div className='flex-[0.5]'>
                      <div className='flex text-sm text-gray-500'>
                        <span>
                          {courseProgress?.total_resources ?? 0} lessons
                        </span>
                        {/* <span className='ml-2'>{course.total_lessons_duration}</span> */}
                      </div>

                      <div className='mt-4'>
                        <div className='bar flex-[0.8] mr-2 rounded-md my-4'>
                          <div
                            className={`h-full rounded-md`}
                            style={{
                              width: progress + '%',
                              backgroundColor: getBarColor(progress),
                            }}
                          ></div>
                        </div>
                        <div className='flex items-center justify-between mt-2'>
                          <span className='text-xs text-gray-500'>
                            {progress + '%'} complete
                          </span>
                        </div>
                      </div>
                    </div>

                    {courseProgress?.total_resources && (
                      <div className='flex-[0.5] flex items-center justify-end'>
                        <button
                          onClick={() =>
                            goToCourse(`/student/my-courses/${courseID}`)
                          }
                          className={`px-4 py-1 text-sm hover:bg-primary rounded text-white bg-secondary`}
                        >
                          {progress === 0 || progress === 100
                            ? 'Start'
                            : 'Resume'}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : 'No data to display'}
    </div>
  );
};

export const MyCourses = () => {
  const navigate = useNavigate();

  const goToCourse = (path: string) => navigate(path);
  const { data, isLoading } = useGetStudentCourses();

  if (isLoading) {
    return (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Head title='My Courses' />
      <TabGroup className='mt-8'>
        <TabList className='flex space-x-1 p-1 border-slate-300 border-b-[0px]'>
          <Tab
            key='All'
            className={({ selected }) => {
              return getTabStyle(selected);
            }}
          >
            All
          </Tab>

          <Tab
            key='In Progress'
            className={({ selected }) => {
              return getTabStyle(selected);
            }}
          >
            In Progress
          </Tab>

          <Tab
            key='Completed'
            className={({ selected }) => {
              return getTabStyle(selected);
            }}
          >
            Completed
          </Tab>
        </TabList>
        {data && data.progress_details && (
          <TabPanels className='mt-2'>
            <TabPanel key={'All Panel'} className={``}>
              {getTabContent('All', data, goToCourse)}
            </TabPanel>

            <TabPanel key={'In Progress Panel'} className={``}>
              {getTabContent(
                'In Progress',
                getCourseByProgessStatus('IN PROGRESS', data),
                goToCourse,
              )}
            </TabPanel>

            <TabPanel key={'Completed Panel'} className={``}>
              {getTabContent(
                'Completed',
                getCourseByProgessStatus('COMPLETED', data),
                goToCourse,
              )}
            </TabPanel>
          </TabPanels>
        )}
      </TabGroup>
    </>
  );
};
