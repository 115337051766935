import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useState } from 'react';

import styles from '../../../utils/styles';

type PasswordInputProps = {
  placeholder: string;
  registration: any;
};

export const PasswordInput = ({
  placeholder,
  registration,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='password-input-container'>
      <input
        autoComplete='new-password'
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        className={clsx(styles.input, 'appearance-none')}
        {...registration}
        data-cy='password'
      />
      {setShowPassword && (
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={() => setShowPassword(!showPassword)}
          className='text-[10px]'
          color='#bbb'
        />
      )}
    </div>
  );
};
