import { TypeOf, object, string, array } from 'zod';

export type LiveSessionProps = {
  id: string;
  category: {
    id: string;
    name: string;
    description: string;
  };
  title: string;
  about: string;
  duration_hours: string;
  duration_weeks: string;
  registration_due_date: string;
  expiration_date: string;
  start_date: string;
  start_time: string; //GMT
  capacity: number;
  price_usd: string;
  price_ngn: string;
  vat_percentage: string;
  discount_percentage: string;
  image: {
    id: string;
    created_by: string;
    image_url: string;
    image_url_compressed: null;
    file_key: string;
    presigned_url: string;
  };
  status: 'upcoming' | 'ongoing' | 'completed' | 'canceled' | 'postponed'; //string;
  location: string;
  rating: string;
  class_days: string[];
  created_by: string;
  current_week: null | number;
  no_of_students: number;
  course_id: string;
};

export const registrationSchema = object({
  payment_provider: string({
    required_error: 'Payment Method is required',
  }).min(1),
  live_session: string().min(1, 'Live Session is required'),
  questions: array(
    object({
      question_id: string(),
      question_text: string(),
      answer_text: string().min(1, 'This field is required'),
    }),
  ),
});

export type RegistrationInput = TypeOf<typeof registrationSchema>;

export type Question = {
  created_by: string;
  id: string;
  live_session: string;
  question_text: string;
};

export type LiveSessionWithQuestions = LiveSessionProps & {
  questions: Question[];
};

export type Answer = {
  question: string;
  answer_text: string;
};

export type RegistrationPayload = {
  live_session: string;
  answers: Answer[];
  payment_provider: string;
};
