import { Grid, Typography, Card, CardContent } from '@mui/material';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';

import {
  AdebayoPicture,
  dapoOdekunle,
  headshotKehinde,
  profileIcon,
} from '../../../assets';

const allCards = [
  {
    image: AdebayoPicture,
    name: 'Bayo Adegun',
    text: 'I recently completed the PostgreSQL course the Instructor taught and was thoroughly impressed by the depth and clarity of the material. The instructor demonstrated exceptional knowledge and passion for the subject, making even the most complex concepts accessible and engaging. Each lesson was well-structured, with plenty of practical exercises reinforcing the theory and encouraging real-world application. The  instructor was very supportive, providing timely and insightful feedback on assignments and always available to answer questions. Whether you are a data analyst, engineer, or scientist mastering SQL is crucial and this course provides the essential skills needed to manage and analyze data effectively. having a solid foundation in SQL is invaluable in the data field, and this course has significantly enhanced my understanding and skills in database management. I am well-prepared to apply these skills in my career. I highly recommend this course to anyone looking to deepen their knowledge of SQL databases.',
    rating: 5.0,
  },
  {
    image: headshotKehinde,
    name: 'Kehinde Fagbohungbe',
    text: 'Words from the bootcamp headline — zero to hero — were what initially caught my attention. How could anyone be so certain of that? True to his words, even though I started the class with barely any knowledge or usage of SQL, at the end of Dr. Olanipekun’s training, I had received so much knowledge, had had so much practice, and been equipped with so much resources that I can implement SQL in my own activities while enthusiastically talking about it to my friends and coworkers. Throughout the live training, which was always on time and very well organized, Dr. Olanipekun implemented a teaching style that ensures no one is left behind — small-group problem solving sessions — even as he emphasized the importance of networking. On top of this, what sets him apart is his unique mix of expertise, empathy, and passion, all while remaining focused on the day’s goal. This made every moment of the bootcamp worth the while, and it was never hard to come back to class for another session. I am  truly glad to have been part of the cohort and really REALLY recommend both the bootcamp and, perhaps more importantly, the instructor!',
    rating: 5.0,
  },
  {
    image: profileIcon,
    name: 'Tolulope Daramola',
    text: 'My bootcamp with  AwesumEdge was a great learning experience. It taught and reinforced me with the SQL knowledge and skills needed for data science and analytics  career path. Our instructor dedicated and shared so much knowledge in class. The program is packed with lots of  exercises and assignments that helped in building a robust project portfolio. We also have access to detailed SQL playlists that run through the very basis of SQL. This gave me the confidence to prepare myself for job applications and interviews. I chose this program to accelerate  my career direction towards Data Analytics and Data Science, which helped me immensely. It gave  me new skills and opened doors to new positions that required those skills. If you ever find yourself contemplating if this program will match your expectations, I can assure you it will exceed it. Special thanks to our instructor. You do it effortlessly and passionately. Keep it up',
    rating: 5.0,
  },
  {
    image: dapoOdekunle,
    name: 'Dapo Odekunle',
    text: 'The class was perfect and is sufficient for all levels (most especially for newbies and someone with an intermediate skill). The material is detailed enough to get a basic understanding of SQL. The tutor makes the class interacting, fun and enjoyable.',
    rating: 5.0,
  },
];

export const Testimonials = () => {
  const [displayedCards, setDisplayedCards] = useState<
    { image: string; name: string; text: string; rating: number }[]
  >([]);

  const [expandedTestimonialID, setExpandedTestimonialID] =
    useState<number>(-1);

  // Function to randomly select 3 cards
  const getRandomCards = () => {
    // Shuffle the array of cards
    const shuffledCards = [...allCards].sort(() => Math.random() - 0.5);

    // Select the first 3 shuffled cards
    const selectedCards = shuffledCards.slice(0, 3);

    return selectedCards;
  };

  // Function to update displayed cards
  const updateDisplayedCards = () => {
    const newCards = getRandomCards();
    setDisplayedCards(newCards);
  };

  useEffect(() => {
    // Initially display 3 random cards
    const initialCards = getRandomCards();
    setDisplayedCards(initialCards);

    // Set timeout to update cards after 24 hours
    const timeoutId = setTimeout(updateDisplayedCards, 24 * 60 * 60 * 1000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className='px-8 py-8 min-h-[60vh] text-primary'
      style={{ paddingBottom: '40px' }}
    >
      <div
        style={{
          textAlign: 'center',
          paddingTop: '30px',
          fontWeight: 'bolder',
          lineHeight: '1em',
          fontSize: 'clamp(24px, 4vw, 42px)',
          paddingBottom: '20px',
          color: 'rgba(13, 6, 95, 1)',
        }}
      >
        <h1>Don’t Take Our Word For It</h1>
      </div>
      <div style={{ paddingTop: '20px' }}>
        <Grid container spacing={2}>
          {displayedCards.map((card, index) => {
            const showMoreBtn = card.text.length > 300;
            const cardText = showMoreBtn
              ? card.text.substring(0, 300) + '...'
              : card.text;

            return (
              <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                <Card
                  sx={{
                    borderRadius: '20px',
                    boxShadow: '15px 15px 85px rgba(7, 1, 2, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, .4)',
                    },
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingLeft: '25px',
                      paddingTop: '20px',
                    }}
                  >
                    {card.name === 'Tolulope Daramola' ? (
                      <div className='flex bg-gray-200 rounded-full h-[80px] w-[80px] items-center justify-center'>
                        <img
                          src={card.image}
                          alt='testimonial card'
                          style={{
                            height: '60px',
                            width: '60px',
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        src={card.image}
                        alt='testimonial card'
                        style={{
                          borderRadius: '50px',
                          height: '80px',
                          width: '80px',
                        }}
                      />
                    )}

                    <Typography
                      sx={{
                        pl: '5px',
                        fontWeight: 'bolder',
                        fontSize: '20px',
                        paddingTop: '25px',
                        color: 'rgba(29, 29, 69, 1)',
                      }}
                    >
                      {card.name}
                    </Typography>
                  </div>
                  <CardContent>
                    <Typography
                      sx={{
                        color: 'rgba(126, 126, 170, 1)',
                        pl: '10px',
                        fontSize: '13px',
                      }}
                    >
                      {index === expandedTestimonialID ? card.text : cardText}
                      {showMoreBtn && index !== expandedTestimonialID && (
                        <button
                          onClick={() => setExpandedTestimonialID(index)}
                          className='ml-2 text-blue-600'
                        >
                          show More
                        </button>
                      )}
                      {showMoreBtn && index === expandedTestimonialID && (
                        <button
                          onClick={() => setExpandedTestimonialID(-1)}
                          className='ml-2 text-blue-600'
                        >
                          show less
                        </button>
                      )}
                    </Typography>
                    <Typography sx={{ paddingTop: '40px' }}>
                      <Stack spacing={1}>
                        <Rating
                          name='rating'
                          defaultValue={card.rating}
                          precision={0.5}
                          readOnly={true}
                        />
                      </Stack>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
