import { useMutation } from '@tanstack/react-query';

import { axios } from '../../../lib/axios';
import { ResetPasswordInput } from '../routes/ResetPassword';

const resetPassword = async (payload: ResetPasswordInput) => {
  const response = await axios.post('/user/reset-password/', payload);
  return response.data;
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};
