import { useLocation } from 'react-router-dom';

import { Footer } from './Footer';
import { Navbar } from './Navbar';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const page: string = location.pathname.split('/')[1];

  return (
    <div className='2xl:container 2xl:mx-auto'>
      <Navbar activePage={page} />
      <div className=''>{children}</div>
      <Footer />
    </div>
  );
};
