import React from 'react';

import {
  cartIcon,
  currencyIcon,
  historyIcon,
  infoIcon,
} from '../../../../assets';
import { Spinner } from '../../../../components/Elements';
import { Head } from '../../../../components/Head';
import { useGetUpcomingLiveSessions } from '../../../liveworkshops/api';
import { useGetDashboardData } from '../api';

const whiteCardsData = [
  {
    title: 'Number of Courses Sold',
    iconBgColor: '#FFFBEB',
    icon: cartIcon,
    figure: '2234',
    isCurrency: false,
    percentage: '', //'+12%',
  },

  {
    title: 'Total Revenue',
    iconBgColor: '#EFF6FF',
    icon: currencyIcon,
    figure: '$10,566',
    percentage: '', //'+35%',
  },

  {
    title: 'Total Content Watched',
    iconBgColor: '#FDF2F8',
    icon: historyIcon,
    figure: '0', //'1273hr 47m',
  },

  {
    title: 'Registered Students',
    iconBgColor: '#EFF6FF',
    icon: currencyIcon,
    figure: '556',
    percentage: '', //'+15%',
  },
];

const colouredCardsData = [
  {
    title: 'Total Courses Available',
    count: 0,
    bgColor: '#D8EBFE',
  },
  {
    title: 'Upcoming Live sessions',
    count: 2,
    bgColor: '#E3FFE7',
  },
];

const WhiteCard: React.FC<{
  title: string;
  iconBgColor: string;
  icon: any;
  figure: string;
  percentage?: string;
  index: number;
}> = ({ title, iconBgColor, icon, figure, percentage, index }) => {
  return (
    <div
      className={`${index === 3 ? 'mr-0' : index === 1 ? 'sm:mr-0 xl:mr-4' : 'md:mr-4'} mb-4 flex flex-[0.5] lg:flex-[0.25] flex-wrap sm:mb-0 bg-white px-4 rounded-lg shadow-all-sides h-[100px] items-center py-4`}
    >
      <div
        style={{
          backgroundColor: iconBgColor,
        }}
        className='flex-[0.2] h-[60%] rounded-xl flex items-center justify-center'
      >
        <img src={icon} className='h-6' alt='cart icon' />
      </div>
      <div className='flex-[0.8] ml-2'>
        <span className='text-xs text-slate-500 font-semibold'>{title}</span>
        <div className='flex'>
          <span className='font-bold'>{figure}</span>
          {percentage && (
            <span
              className={`${percentage[0] === '+' ? 'text-green-500' : 'text-red-500'} ml-2 text-sm`}
            >
              {percentage}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const ColouredCard: React.FC<{
  title: string;
  count: number;
  bgColor: string;
}> = ({ title, count, bgColor }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
      }}
      className='h-[200px] w-[300px] mr-4 rounded-lg p-8 flex flex-col items-center justify-center'
    >
      <span className='text-center text-slate-500 font-semibold text-sm'>
        {title}
      </span>
      <h3 className='text-center mt-8 text-2xl font-bold'>{count}</h3>
    </div>
  );
};

export const Dashboard = () => {
  const { data, isFetching } = useGetDashboardData();
  const { data: upcomingSessions, isFetching: isFetchingUpcomingSessions } =
    useGetUpcomingLiveSessions();

  return isFetching || isFetchingUpcomingSessions ? (
    <div className='min-h-[50vh] flex flex-col  items-center justify-center'>
      <Spinner size='md' />
    </div>
  ) : (
    <>
      <Head title='Admin Dashboard' />
      <div className='flex justify-end items-center -mt-4'>
        <img src={infoIcon} className='h-5' alt='info icon' />
        <span className='text-sm text-slate-500 font-medium'>Last 28 days</span>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 grid-template-columns: 1fr 1fr 1fr gap-2 mt-4 justify-between'>
        {whiteCardsData.map(
          ({ title, iconBgColor, icon, percentage, figure }, index) => (
            <WhiteCard
              key={title}
              title={title}
              iconBgColor={iconBgColor}
              icon={icon}
              percentage={percentage}
              figure={
                index === 0
                  ? data?.course_registrations || 0
                  : index === 1
                    ? `$${data?.total_revenue || 0}`
                    : index == 3
                      ? data?.total_students || 0
                      : figure
              }
              index={index}
            />
          ),
        )}
      </div>

      <div className='flex mt-8 justify-center'>
        {colouredCardsData.map(({ title, count, bgColor }, index: number) => (
          <ColouredCard
            key={title}
            title={title}
            count={
              index === 0
                ? data?.total_courses || 0
                : upcomingSessions?.count || 0
            }
            bgColor={bgColor}
          />
        ))}
      </div>
    </>
  );
};
