import { ErrorProps } from '../types';

const getErrorMsg = (error: ErrorProps) => {
  const msg = error.response?.data.message || error.message;
  if (typeof msg === 'object' && (msg as any).non_field_errors) {
    return (msg as any).non_field_errors[0];
  }
  return msg;
};

export default getErrorMsg;
