import { useQuery } from '@tanstack/react-query';

import { axios } from '../../../../lib/axios';

export const getDashboardData = async () => {
  const resp = await axios.get('/adminapp/stats');
  return resp.data;
};

export const useGetDashboardData = () => {
  return useQuery({
    queryKey: ['admin-dashboard-data'],
    queryFn: getDashboardData,
    select: (data) => data.data,
    staleTime: 24 * 60 * 60 * 1000,
  });
};
