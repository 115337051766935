import React from 'react';

import { certificate, dashedLine, learn, workReady } from '../../../assets';

type DataProps = {
  icon: any;
  title: string;
  description: string;
};

const data: DataProps[] = [
  {
    icon: learn,
    title: '01. Learn',
    description:
      'Learn from our industry experts who have taken time to build a well-structured curated curriculum to ensure you take your skills from zero to hero.',
  },
  {
    icon: certificate,
    title: '02. Certificate',
    description: 'You worked hard for it! You deserve it! Flaunt it!',
  },
  {
    icon: workReady,
    title: '03. Work Ready',
    description:
      'We teach industry relevant courses that make you work ready and use the hands-on project approach to ensure you are well grounded for the journey ahead.',
  },
];

export const WhyTakeOurCourses = () => {
  return (
    <div className='px-8 py-8 bg-secondary min-h-[300px] text-primary'>
      <h3 className='text-center sm:text-left mb-0 sm:mb-4 text-2xl sm:text-4xl font-bold'>
        Why Take Our Courses?
      </h3>

      <div className='flex flex-col sm:flex-row sm:justify-start mt-8 sm:mt-16'>
        {data.map((item, index) => {
          return (
            <div key={item.title} className='flex '>
              {index === 1 && (
                <img
                  src={dashedLine}
                  className='h-[250px] hidden sm:block'
                  alt='Dashed Line'
                />
              )}
              <div
                key={item.title}
                className={`${index === 2 ? 'mb-0' : 'mb-8'}  flex flex-col items-center justify-between px-4 h-[200px]`}
              >
                <div className='flex flex-col flex-[0.3] items-center'>
                  <img src={item.icon} alt='Learn Icon' className='h-10 w-10' />
                  <h3 className='my-4 text-xl font-bold'>{item.title}</h3>
                </div>

                <div className='flex flex-[0.7]'>
                  <span className='text-center font-light'>
                    {item.description}
                  </span>
                </div>
              </div>
              {index === 1 && (
                <img
                  src={dashedLine}
                  className='h-[250px] hidden sm:block'
                  alt='Dashed Line'
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
